import authAxios from "../../utils/authAxios";
import { APIURL } from "../../constants/apiConstants";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getAppointments = createAsyncThunk('appointments/get', async (data, thunkAPI) => {

    try {
        const response = await authAxios.get(APIURL.APPOINTMENT_LIST);
        return response.data;

    } catch (error) {
        const message = (error.response
            && error.response.data
            && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})

const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState: {
        upcoming_appointment: {},
        completed_appointment: {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: ''

    },
    reducers: {

        setAppointments(state, action) {

            const { upcoming_appointment, completed_appointment } = action.payload;

            state.upcoming_appointment = upcoming_appointment;
            state.completed_appointment = completed_appointment;
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(getAppointments.pending, (state) => {

                state.isLoading = true;
            })
            .addCase(getAppointments.fulfilled, (state, action) => {

                const { upcoming_appointment, completed_appointment } = action.payload;

                state.isLoading = false;
                state.isSuccess = true;
                state.upcoming_appointment = upcoming_appointment;
                state.completed_appointment = completed_appointment;
            })
            .addCase(getAppointments.rejected, (state, action) => {

                state.isLoading = false;
                state.isError = true;
                state.upcoming_appointment = {};
                state.completed_appointment = {};
                state.message = action.payload;
            })

    }
});

export const appointmentsAction = appointmentsSlice.actions;

export default appointmentsSlice;