import { Nav } from 'react-bootstrap';

const ButtonSpinner = (props) => {

    return (<><Nav.Item>
    
    <span className={`spinner-border ${props.color ? props.color : null} spinner-border-sm`} role="status" aria-hidden="true">
    </span> {props.children}
    </Nav.Item>
    </>)
}

export default ButtonSpinner;