import { createSlice } from "@reduxjs/toolkit";

const playPackSlice = createSlice({
  name: "playPacks",
  initialState: {
    playPacks: {},
  },
  reducers: {
    setPlayPacks(state, action) {
      state.playPacks = action.payload;
    },
    reset(state) {
      state.playPacks = {};
    },
  },
});

export const playPacksAction = playPackSlice.actions;
export default playPackSlice;
