import React from "react";
import styled from "styled-components";

const ReferralCard = (props) => {
  const { text, value } = props;

  return (
    <ListviewOuter>
      <h3 className="h3font">{text}</h3>
      <span className="value">{value}</span>
    </ListviewOuter>
  );
};

const ListviewOuter = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 30px 10px 10px;
  margin-bottom: 15px;
  padding: 10px 30px 10px 10px;
  margin-bottom: 15px;
  padding-left: 15px;
  height: auto;

  @media (min-width: 0px) and (max-width: 767px) {
    padding: 9px 16px 9px 9px;
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 10px 30px 10px 10px;
    margin-bottom: 15px;
    padding: 10px 30px 10px 10px;
    margin-bottom: 15px;
    padding-left: 15px;
    height: auto;
  }

  .h3font {
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 11px;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
      font-size: 17px;
    }
  }
  .value {
    color: #367c61;
    font-weight: bold;
    font-size: 18px;
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 16px;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
      font-size: 18px;
    }
  }
`;

export default ReferralCard;
