import { Helmet } from "react-helmet";
import Styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import Backbtn from "../../../components/UI/Backbtn/Backbtn";
import { useEffect } from "react";
import Call_Office from "../../../assets/images/Appoiment/Call_Office.svg";
import Location from "../../../assets/images/Appoiment/Location.svg";

const PlayPackDetails = () => {
  const { packId } = useParams();
  const navigate = useNavigate();
  const { myPlans } = useSelector((state) => state.myPlans);
  const packData = myPlans?.play_packs?.find((pack) => pack.id == packId);
  const { locations } = useSelector((state) => state.locations);

  useEffect(() => {
    if (!packData?.id) navigate("/my-plans/packs");
  }, [packData, navigate]);

  const locationData = locations?.find(
    (obj) => obj.meta.locations_meta_vetspire_id == packData?.location?.id
  );

  return (
    <>
      <Helmet>
        <title>Play pack details| My H+P</title>
        <meta
          name="description"
          content="Get 24/7 access to your pet's medical records and info, review upcoming & past appointments, and find Heart + Paw and H+P Family Partner centers near you."
        />
      </Helmet>
      <Backbtn
        Back
        heading={`Purchased Package Details`}
        path="/my-plans/packs"
      />

      <ViewPlanBenefits>
        <div className="ViewPlanContent">
          <div className="contents left">
            <div className="pack-name">{packData?.name}</div>
            <p className="price-name">${packData?.totalPaid}</p>
          </div>
          <div className="contents right">
            <ProgressBar
              now={
                (packData?.quantityUsed /
                  (packData?.quantity + packData?.quantityUsed)) *
                100
              }
            />
            <p className="days-left">
              {" "}
              {packData?.quantity - packData?.quantityUsed} days left
            </p>
          </div>
        </div>
        <div className="line-align"></div>
        <div className="parent-card">
          <div class="card w-40 cardsummary-2">
            <div class="card-title card-image">
              <img
                className="image-size"
                // src="https://heartandpaw.com/wp-content/uploads/2022/06/animal-medical-center-certereach-exterior.jpg"
                src={
                  locationData?.img.link != undefined
                    ? locationData?.img.link
                    : "https://heartandpaw.com/wp-content/uploads/2022/06/animal-medical-center-certereach-exterior.jpg"
                }
              />
            </div>
          </div>
          <div class="card w-100 cardsummary">
            <div className="card-combined">
              <div className="location-data">
                <div class="location-name">
                  {packData?.location.displayName}
                </div>
                <div className="location-addr">
                  <img className="loc-image" src={Location} />
                  <span className="loc-desc">{packData?.location.address}</span>
                </div>
                <div className="location-addr">
                  <img className="loc-image" src={Call_Office} />
                  <span className="loc-desc">
                    {packData?.location.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ViewPlanBenefits>
    </>
  );
};

const ViewPlanBenefits = Styled.div`
height: auto;
width: 744px;
border-radius: 10px;
background-color: #FFFFFF;
margin:0 auto;
margin-bottom:10px;
padding: 32px 36px 16px 36px;
@media (min-width: 768px) and (max-width: 1024px) {
  width:596px;
  padding: 32px 28px 7px 28px;

}  

@media (min-width: 0px) and (max-width: 767px) {
   width:100%;
   padding:24px 19px 6px 19px;
}
& .ViewPlanContent{
  display: flex;
  & .contents{
    flex:1;
    &.left{
        & .pack-name{
            font-family:Sailec-Bold;
            font-size:20px;
            line-height:29px;
            color: #195750;
            @media (min-width: 0px) and (max-width: 767px){
                font-size:18px;
            }
        }
        & .price-name{
           font-family:Sailec-Medium;
            font-size:16px;
            line-height:22px;
            color: #000000;     
            padding-top: 6px;   
            padding-bottom: 15px;
            @media (min-width: 0px) and (max-width: 767px){
                font-size:14px;
            }
        }
    }
    &.right{
        @media (min-width: 0px) and (max-width: 767px) {
        padding-top: 5px;
        }
      & .progress{
        height:8px;
        width: 227px;
        background-color: #D9D9D9;
        margin-left: auto;
        
          @media (min-width: 0px) and (max-width: 767px) {
            width: 100%;
            margin:0;
          }
         & .progress-bar{
          background-color: #195750;
         }
        
      }
         & .days-left{
            justify-content: flex-end !important;
            display: flex !important;
            font-family:Sailec-Medium;
            font-size:14px;
            @media (min-width: 0px) and (max-width: 767px){
                font-size:12px;
            }
        }
    }
  }
}
& .line-align {
    border: 1px solid #979797;
    border-radius: 10px;
    padding-top: 0px;
  }

& .parent-card {
    width: 100%;
    display: flex;

    & .cardsummary {
      padding: 18px 18px 18px 18px;
      border: 3px solid #fff;
      border-radius: 10px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      & .location-data {
        & .location-name {
          display: flex;
          font-family: Sailec-Bold;
          font-size: 16px;
          line-height: 23px;
          color: #195750;
          @media (min-width: 0px) and (max-width: 767px) {
            font-size: 14px;
          }
          & .center-name {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          & .dist {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            & .dist-image {
              height: 24px;
              width: 17px;
            }
            & .dist-text {
              font-family: Sailec-Medium;
              color: #000000;
              font-size: 14px;
              @media (min-width: 0px) and (max-width: 767px) {
                font-size: 10px;
              }
            }
          }
        }
        & .location-addr {
            padding-top: 21px;
          @media (min-width: 768px) and (max-width: 1024px) {
            padding-top: 23px;
          }
          @media (min-width: 0px) and (max-width: 767px) {
            padding-top: 17px;
          }
          & .loc-image {
            padding-right: 10px;
          }
          & .loc-desc {
            @media (min-width: 768px) and (max-width: 1024px) {
            }
            @media (min-width: 0px) and (max-width: 767px) {
              font-size: 10px;
            }
          }
        }
      }
    }
    & .cardsummary-2 {
      padding: 22px 22px 21px 0px;
      border: 3px solid #fff;
      border-radius: 10px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      @media (min-width: 323px) and (max-width: 686px) {
        width: 30% !important;
        padding: 24px 22px 0px 0px !important;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 25% !important;
      }
      & .card-image {
        & .image-size {
          height: 100px;
          width: 102px;
          border-radius: 10px;
            @media (min-width: 0px) and (max-width: 432px){
            height: 87px;
            width: 56px;
            border-radius: 10px;
            }
          @media (min-width: 433px) and (max-width: 767px) {
            height: 76px;
            width: 77px;
            border-radius: 10px;
          }
            @media (min-width: 768px) and (max-width: 1024px){
            height: 101px;
            width: 89px;
            border-radius: 10px;
            }
        }
      }
    }
  }

& .Benefits_content_count{
color: #2D2D2D;
font-family: Sailec-Medium;
font-size: 15px;
line-height: 21px;
}
& .content{
color: #367C61;
font-family: Sailec-Bold;
font-size: 16px;
line-height: 23px;
}
hr {
    margin:9px 0px;
}
`;

export default PlayPackDetails;
