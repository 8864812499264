import authAxios from '../utils/authAxios';
import { APIURL } from '../constants/apiConstants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getMyPlans = createAsyncThunk('myPlans/get', async (data, thunkAPI) => {

    try {

        const response = await authAxios.get(APIURL.PLAN_LIST)
        console.log(response.data);
        return response.data;
    } catch (error) {
        const message = (error.response
            && error.response.data
            && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})

const myPlanSlice = createSlice({
    name: 'myPlans',
    initialState: {
        myPlans: [],
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: ''
    },
    extraReducers: (builder) => {

        builder
            .addCase(getMyPlans.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMyPlans.fulfilled, (state, action) => {

                state.isLoading = false;
                state.isSuccess = true;
                state.myPlans = action.payload;
            })
            .addCase(getMyPlans.rejected, (state, action) => {

                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })
    }

});

export const myPlansAction = myPlanSlice.actions;
export default myPlanSlice;