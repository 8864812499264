import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const StyledButton = styled(Button).attrs((props) => ({}))`
  border-radius: 12px;
  background-color: #367c61;
  color: #ffffff;
  font-size: 16px;
  height: 55px;
  border: none;
  &:hover,
  &:focus {
    background-color: #367c61;
    color: #ffffff;
  }
`;

export const StyledButtonGreen = styled(StyledButton)`
  background-color: #367c61;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.14);

  &:hover,
  &:focus {
    background-color: #367c61;
    color: #ffffff;
  }
  a {
    color: #ffffff;
  }

  &.btn-primary:disabled {
    color: #ffffff;
    background-color: #367c61 !important;
    border-color: #367c61;
  }
`;

export const StyledButtonGreensmall = styled(StyledButton)`
  height: 50px;
  border-radius: 8px;
  background-color: #367c61;
  font-family: Sailec-Bold;
  font-size: 14px;
  line-height: 20px;
  box-shadow: unset;
`;

export const StyledButtonWhite = styled(StyledButton)`
  background-color: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.14);
  color: #007e5f;
  border: none; // border: 2px solid #367C61;
  &:hover,
  &:focus {
    background-color: #ffffff;
    color: #007e5f;
  }
`;

export const StyledButtonFacebook = styled(StyledButton)`
  background-color: #1868f2;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;

  img {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
  &:hover,
  &:focus {
    background-color: #1868f2;
    color: #ffffff;
  }
`;

export const StyledButtonGoogle = styled(StyledButton)``;
/*`
  background-color: rgb(255 255 255 / 50%);
  width: 100%;
  display: inline-block;
  & .google_btn {
    width: 100%;
    height: 100%;

    box-shadow: none !important;
    background-color: rgb(255 255 255 / 50%);
    border-radius: 10px !important;
    color: #2a2a2a !important;
    font-size: 12px !important;
    padding: 10px !important;
    div {
      padding: 0px !important;
      margin-right: 4px !important;
      svg {
        display: none;
      }
    }

    img {
      width: 15px;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      // top: px;
    }

    &:hover,
    &:focus {
      opacity: 1 !important;
    }
    span {
      color: #2a2a2a !important;
      font-family: Sailec-Medium !important;
      font-size: 12px !important;
      padding: 10px 0px 10px 0px !important;
      vertical-align: middle !important;
    }
  }
`*/
