import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "../store/authSlice";
import { SESSION_TIME_OUT } from "../constants";
import { storeUserSessionTime, retriveUserSessionTime, getCurrentDateTime } from "../utils/Utils";
import authAxios from "../utils/authAxios";
import { APIURL } from "../constants/apiConstants";
import paths from "../constants/paths";

/* This hook is responsible for -
* - if there is no activity within 15 min. logout the system
* - This is not valid for remembered me users
*/


const useSessionExpire = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        const retriveSessionTime = retriveUserSessionTime();

        if (retriveSessionTime) {

            const sessionDateTime = new Date(retriveSessionTime);
            const currentTime = getCurrentDateTime().getTime();
            const sessionTime = sessionDateTime.getTime();

            if ((currentTime - sessionTime) / 1000 / 60 < SESSION_TIME_OUT) {

                storeUserSessionTime();

            } else {
                authAxios
                .get(APIURL.LOGOUT)
                .then((response) => {
                    dispatch(authAction.setLogout());
                    navigate(paths.WELCOME.pathName + "/" + paths.LOGIN.pathName);
                })
                .catch((error) => {
                    dispatch(authAction.setLogout());
                });
            }
        }

    }, [location, dispatch]);
};

export default useSessionExpire;