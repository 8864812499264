import { createSlice } from "@reduxjs/toolkit";
// Put theme related stuff 
const themeSettingSlice = createSlice({
    name: 'themeSetting',
    initialState: {
        referFriendAlert: true
    },
    reducers: {
        referFriendAlertOff(state) {
            state.referFriendAlert = false
        }
    }
})

export const themeSettingAction = themeSettingSlice.actions;

export default themeSettingSlice;