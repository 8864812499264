import authAxios from '../utils/authAxios';
import { APIURL } from '../constants/apiConstants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getInvoice = createAsyncThunk('invoice/get', async (data, thunkAPI) => {

    try {

        const response = await authAxios.get(APIURL.INVOICE_DETAILS)
        return response.data;
    } catch (error) {
        const message = (error.response
            && error.response.data
            && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})

const invoiceSlice= createSlice({
    name: 'invoice',
    initialState: {
        invoice:[],
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: ''
    },
    extraReducers: (builder) => {

        builder
            .addCase(getInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInvoice.fulfilled, (state, action) => {

                state.isLoading = false;
                state.isSuccess = true;
                state.invoice = action.payload;
            })
            .addCase(getInvoice.rejected, (state, action) => {

                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })
    }

});

export const invoiceAction = invoiceSlice.actions;
export default invoiceSlice;