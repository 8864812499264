import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '../../utils/authAxios';
import { APIURL } from '../../constants/apiConstants';

export const getAppointmentDetails = createAsyncThunk('appointmentDetails/get', async (data, { rejectWithValue }) => {

    try {

        const response = await authAxios.post(APIURL.APPOINTMENT_DETAILS, {
            appointment_id: data.appointment_id
        })

        return response.data;
    } catch (err) {

        return rejectWithValue(err.data);
    }
});


const appointmentDetailsSlice = createSlice({

    name: 'appointmentDetails',
    initialState: {
        appointmentDetails: {},
        appointmentCount: null,
        isLoading: false,
        isSuccess: false,
        error: { isTrue: false },
    },
    reducers: {

        resetError(state) {
            state.error = { isTrue: false }
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(getAppointmentDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAppointmentDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = { isTrue: false };
                state.isSuccess = true;
                state.appointmentDetails = action.payload.appointment_details;
                state.appointmentCount = action.payload.appointment_count;
            })
            .addCase(getAppointmentDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.appointmentDetails = {};
                state.appointmentCount = null;
                state.error = { isTrue: true, ...action.payload };
            })
    }

});

export const appointmentDetailsAction = appointmentDetailsSlice.actions;
export default appointmentDetailsSlice;