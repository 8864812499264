import authAxios from "../utils/authAxios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIURL } from "../constants/apiConstants";
import { retrieveFromSession, saveToSession } from "../utils/Utils";

export const getMyProfile = createAsyncThunk("myProfile/get", async () => {
  const response = await authAxios.get(APIURL.MY_PROFILE);
  saveToSession("HP_MYPROFILE", response.data);
  return response.data;
});

export const updateNotificationSetting = createAsyncThunk(
  "myProfile/update",
  async (data) => {
    const response = await authAxios.post(APIURL.NOTIFICATION_SETTINGS, data);
    return response.data;
  }
);

const profileData = retrieveFromSession("HP_MYPROFILE");

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState: {
    profileDetails: {
      externalCommPreferences: profileData?.profile_details
        ?.externalCommPreferences ?? [{}],
    },
    isLoading: false,
    isError: false,
    isSuccess: false,
    isUpdating: false,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileDetails = action.payload.profile_details;
      })
      .addCase(getMyProfile.rejected, (state) => {
        state.isError = true;
      })
      .addCase(updateNotificationSetting.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateNotificationSetting.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.profileDetails.externalCommPreferences = [
          action.payload.profile_details,
        ];
      })
      .addCase(updateNotificationSetting.rejected, (state) => {
        state.isUpdating = false;
      });
  },
});

export const myProfileAction = myProfileSlice.actions;

export default myProfileSlice;
