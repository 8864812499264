import authAxios from '../../utils/authAxios';
import { APIURL } from '../../constants/apiConstants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getNotificationList = createAsyncThunk('notifications/get', async (data, thunkAPI) => {

    try {

        const response = await authAxios.get(APIURL.NOTIFICATION_LIST)
        return response.data;
    } catch (error) {

        const message = (error.response
            && error.response.data
            && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})

const bellIconNotificationsSlice= createSlice({

    name: 'notificationList',
    initialState: {
        notificationList: [],
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: ''
    },
    extraReducers: (builder) => {

        builder
            .addCase(getNotificationList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getNotificationList.fulfilled, (state, action) => {

                state.isLoading = false;
                state.isSuccess = true;
                state.notificationList = action.payload;
            })
            .addCase(getNotificationList.rejected, (state, action) => {

                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })
    }

});

export const notificationsAction = bellIconNotificationsSlice.actions;
export default bellIconNotificationsSlice;