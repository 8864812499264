import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import style from './Loader.module.css';
import { Spinner } from 'react-bootstrap';
import Loader_logo from '../../../assets/images/Loader_logo.svg';
import styledComponents from 'styled-components';

const Backdrop = () => {
    return <div className={style.backdrop} />
}

const LoaderOverlay = props => {

    return (
        <Loaders>
        <div className={style.loader}>
            <Spinner className={style['spinner-loader']} animation="border" variant="success" />
            {/* <div>Loading . . .</div> */}
            <div className='loader_logo'>
             <img src={Loader_logo} className="loader_img"/>
            </div>
        </div>
        </Loaders>
    );

}

const Loader = () => {
    const portalElement = document.getElementById('overlays');
    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(<LoaderOverlay></LoaderOverlay>, portalElement)}
        </Fragment>
    )
}

const Loaders = styledComponents.div`
& .spinner-border{
    height: 77px;
    width: 77px;
    border-width: 7px;
    margin: 0 auto;
    @media (min-width: 0px) and (max-width: 767px) {
        height: 52px;
        width: 52px;
        border-width: 5px;
    }
}

& .loader_logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    & .loader_img{
        width: 42px;
        height: auto;
        @media (min-width: 0px) and (max-width: 767px) {
            height: 29px;
            width: 28px;
        }
     }
    
`





export default Loader;