import Backbtn from "../../../../../src/components/UI/Backbtn/Backbtn.js";
import Styled from "styled-components";
import DownArrow from "../../../../assets/images/ViewPlan/Dropdown.svg";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/atoms/Loader/Loader";
import { getMyPlanDetails, myPlanDetailsAction } from "../../../../store/myPlanDetailsSlice";
import ProgressBar from "react-bootstrap/ProgressBar";
import Badge from "react-bootstrap/Badge";
import { ListGroup } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import ErrorAlert from "../../../../components/atoms/ErrorAlert/ErrorAlert.jsx";

const ViewPlan = () => {
	const { myPlanId } = useParams();
	const [selectedarraow, setselectedarrow] = useState(false);
	const [daysRemaining, setDaysRemaining] = useState();
	const [totalAvailableBenifits, setAvailableBenifits] = useState(0);
	const [totalComletedBenifits, setCompletedBenifits] = useState(0);

	const { isLoading, myPlanDetails, error } = useSelector(
		(state) => state.myPlanDetails
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getMyPlanDetails({ plan_id: myPlanId }));
	}, []);

	useEffect(() => {
		if (Object.keys(myPlanDetails).length > 0) {
			let enddate = new Date(myPlanDetails.endDate).getTime();
			let startdate = new Date(myPlanDetails.startDate).getTime();
			let currentdate = new Date().getTime();
			let differenceInDays = Math.round(
				(enddate - currentdate) / (1000 * 3600 * 24)
			);
			if (differenceInDays > 0) {
				setDaysRemaining(differenceInDays);
			} else {
				setDaysRemaining("Expired");
			}
		}
	}, [myPlanDetails]);

	useEffect(() => {
		if (Object.keys(myPlanDetails).length > 0) {
			const filteredCB = myPlanDetails.items.filter((item) => {
				return item.quantity > 0 && item.quantity === item.quantityUsed;
			});
			const filteredAB = myPlanDetails.items.filter((item) => {
				return item.quantity > item.quantityUsed;
			});
			if (filteredCB.length) {
				const count = filteredCB.reduce((a, c) => {
					return a + Number(c.quantity);
				}, 0);
				setCompletedBenifits(count);
			} else {
				setCompletedBenifits(0);
			}
			if (filteredAB.length) {
				const count = filteredAB.reduce((a, c) => {
					return a + Number(c.quantity);
				}, 0);
				setAvailableBenifits(count);
			} else {
				setAvailableBenifits(0);
			}
		}
	}, [myPlanDetails]);

	const onshowhandler = () => {
		setselectedarrow(!selectedarraow);
	};

	const onClosedErrorModalHandler = () => {
		dispatch(myPlanDetailsAction.resetError())
	}

	if (isLoading)
		return <Loader />;

	return (
		<>

			<ViewPlans>
				<Backbtn
					Back
					heading={`Prevention Plan for  ${myPlanDetails?.patient?.name ?? ''}`}
					path="/my-plans/plans"
				/>

				{error?.isTrue &&
					<ErrorAlert
						show={error?.isTrue}
						defaultMessage={error?.message}
						onClose={onClosedErrorModalHandler}
					/>}


				{Object.keys(myPlanDetails).length > 0 && (<>
					<ViewPlanBenefits>
						<div className="ViewPlanContent">
							<div className="contents left">
								<h3>Prevention Plan</h3>
								{daysRemaining > 0 ? (
									<p>{`${daysRemaining} days remaining`}</p>
								) : (
									<p>{daysRemaining}</p>
								)}
							</div>
							<div className="contents right">
								<ProgressBar
									now={
										(totalComletedBenifits /
											(totalAvailableBenifits + totalComletedBenifits)) *
										100
									}
								/>
							</div>
						</div>

						<AccordionNew>
							<Accordion defaultActiveKey={["0"]} alwaysOpen>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										<Benefits
											onClick={onshowhandler}
											src={DownArrow}
											className="Benefits"
										>
											<div className="Benefits_content left">
												<span className="dot"></span>
												<span>Available Benefits</span>
											</div>
											<div className="Benefits_content right">
												{" "}
												<span className="Benefits_content_count">
													{totalAvailableBenifits}{" "}
												</span>
												<img src={DownArrow} />
											</div>
										</Benefits>
									</Accordion.Header>
									<Accordion.Body>
										<OpenBenifits className="open_benifits">
											<ListGroup>
												{myPlanDetails &&
													myPlanDetails.items.map((item) => {
														if (item.quantity > item.quantityUsed) {
															return (
																<>
																	<ListGroup.Item>
																		<h3>{item.name}</h3>
																		<div className="progress_bar">
																			<div className="progress_bar_item">
																				<div className="count left">
																					<Badge bg="secondary">
																						{Number(item.quantity)}
																					</Badge>
																					<p>
																						(
																						{`${item.quantity - item.quantityUsed
																							} Available`}
																						)
																					</p>
																				</div>
																			</div>
																			<div className="progress_bar_item progress_baring">
																				<ProgressBar
																					now={
																						(item.quantityUsed /
																							item.quantity) *
																						100
																					}
																				/>
																			</div>
																			<div className="progress_bar_item">
																				<span className="count right">
																					<Badge bg="secondary">
																						{Number(item.quantityUsed)}
																					</Badge>
																					{/* <h4>$29.45</h4> */}
																				</span>
																			</div>
																		</div>
																	</ListGroup.Item>
																</>
															);
														}
													})}
											</ListGroup>
										</OpenBenifits>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>
										<Benefits
											onClick={onshowhandler}
											src={DownArrow}
											className="Benefits"
										>
											<div className="Benefits_content left">
												<span className="dot"></span>
												<span>Completed Benefits</span>
											</div>
											<div className="Benefits_content right">
												{" "}
												<span className="Benefits_content_count">
													{totalComletedBenifits}
												</span>
												<img src={DownArrow} />
											</div>
										</Benefits>
									</Accordion.Header>

									<Accordion.Body>
										{totalComletedBenifits > 0 ? (
											<OpenBenifits className="open_benifits">
												<ListGroup>
													{myPlanDetails &&
														myPlanDetails.items.map((item) => {
															if (
																item.quantity > 0 &&
																item.quantity === item.quantityUsed
															) {
																return (
																	<>
																		<ListGroup.Item>
																			<h3>{item.name}</h3>
																			<div className="progress_bar">
																				<div className="progress_bar_item">
																					<div className="count left">
																						<Badge bg="secondary">
																							{item.quantity}
																						</Badge>
																						{/* <h4>$29.45</h4> */}
																						<p>
																							(
																							{`${item.quantity -
																								item.quantityUsed
																								} Available`}
																							)
																						</p>
																					</div>
																				</div>
																				<div className="progress_bar_item progress_baring">
																					<ProgressBar
																						now={
																							(item.quantityUsed /
																								item.quantity) *
																							100
																						}
																					/>
																				</div>
																				<div className="progress_bar_item">
																					<span className="count right">
																						<Badge bg="secondary">
																							{item.quantityUsed}
																						</Badge>
																						{/* <h4>$29.45</h4> */}
																					</span>
																				</div>
																			</div>
																		</ListGroup.Item>
																	</>
																);
															}
														})}
												</ListGroup>
											</OpenBenifits>
										) : null}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</AccordionNew>
					</ViewPlanBenefits>

					<ViewPlanDetails>
						<h3>Plan Details</h3>
						<Details className="d-flex align-items-center">
							<div className="main_div">
								<div className="details">
									<p>Start Date</p>
									<span>{myPlanDetails.formatStartDate}</span>
								</div>
								<div className="details">
									<p>End Date</p>
									<span>{myPlanDetails.formatEndDate}</span>
								</div>
								<div className="details">
									<p>Days Remaining</p>
									<span className="details">{daysRemaining}</span>
								</div>
							</div>
						</Details>
						<Interval>
							<h3>Payment Interval</h3>
							<div className="d-flex align-items-center">
								<div className="main_div">
									<div className="details">
										<p>Total Charged</p>
										<span>
											{"$" + parseFloat(myPlanDetails.total).toFixed(2)}
										</span>
									</div>
									<div className="details">
										<p>Total Plan Cost</p>
										<span className="details">
											{"$" + parseFloat(myPlanDetails.amountCharged).toFixed(2)}
										</span>
									</div>
								</div>
							</div>
						</Interval>
					</ViewPlanDetails>
				</>)}
			</ViewPlans>

		</>
	);
};

const AccordionNew = Styled.div`
& .accordion{

  & .accordion-item{
    border:none;

    &:first-of-type {
      border-bottom:1px solid #979797;
    }

    & .accordion-header{
      & .accordion-button{
        box-shadow:none;
        padding:19px 0px;
        font-size:15px;
        @media (min-width: 0px) and (max-width: 767px) {
          font-size:11px;
          line-height: 16px;
          padding:17px 0px;

        }
        &:not(.collapsed){
          color: #212529;
          background-color: transparent;
         }
         &:after{
           display:none;
         }
      }
    }
    & .accordion-collapse{
      .accordion-body{
        padding:0px;
        padding-bottom:20px;
      }
    }
  }
}


`;

const OpenBenifits = Styled.div`
&.open_benifits{
  border-radius: 10px;
  background-color: #F5F5F5;
  padding:19px 32px 24px 28px;
  @media (min-width: 0px) and (max-width: 767px) {
    padding:14px 18px 20px 18px;
  } 
  & h3{
    opacity: 0.87;
    color: #000000;
    font-family: Sailec-Medium;
    font-size: 14px;
    line-height: 19px;
 
    
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 11px;
      line-height: 14px;
      width: 60%;

    }
    
  }

  & .list-group{
    & .list-group-item{
        padding:0px;
        background:transparent;
        border:none;
        padding-bottom:19px;
        border-bottom:1px solid #979797;
        border-radius: 0px!important;
        margin-top:10px;

    }
  }
  & .progress_bar{
    display:flex;

    & .progress_bar_item{
     & h4{
      @media (min-width: 0px) and (max-width: 767px) {
        font-size:11px;
      }

      }
      &.progress_baring{
          width:100%;
          & .progress{
            width:100%;
            height:7px;
            margin-top:8px;
            & .progress-bar{
              background:#195750;
            }
           }  
        }

      & .count {
        width:75px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          margin-bottom:0px;
        }
        & .badge {
          margin-bottom: 5px;
          background-color: #195750!important;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          align-items: center;
          vertical-align: middle;
          padding: 5px;
        }
      }
    }
  }

}

`;
const ViewPlanBenefits = Styled.div`
height: auto;
width: 744px;
border-radius: 10px;
background-color: #FFFFFF;
margin:0 auto;
margin-bottom:10px;
padding: 32px 36px 6px 36px;
@media (min-width: 768px) and (max-width: 1024px) {
  width:596px;
  padding:32px 28px 27px 28px;

}  

@media (min-width: 0px) and (max-width: 767px) {
   width:100%;
   padding:24px 19px 0px 19px;
}
& .ViewPlanContent{
  display: flex;
  & .contents{
    flex:1;
    & .left{

    }
    &.right{
      & .progress{
        height:8px;
        width: 227px;
        background-color: #D9D9D9;
        margin-left: auto;
         
          @media (min-width: 0px) and (max-width: 767px) {
            width: 100%;
            margin:0;
          }
         & .progress-bar{
          background-color: #195750;
         }
      }
    }
  }
}


& .Benefits_content_count{
color: #2D2D2D;
font-family: Sailec-Medium;
font-size: 15px;
line-height: 21px;
}
& .content{
color: #367C61;
font-family: Sailec-Bold;
font-size: 16px;
line-height: 23px;
}
hr {
    margin:9px 0px;
}
`;

const Benefits = Styled.div`
cursor:pointer;
display:flex;
width:100%;

& .Benefits_content {
  flex:1;
  align-items: center;
  @media (min-width: 0px) and (max-width: 767px) {
    flex: auto;
  }
  &.left{
    & .dot{
      height: 7px;
      width: 7px;
      background-color: #195750;
      vertical-align: middle;
      display:inline-block;
      border-radius: 50%;

    }
    & span{
      vertical-align: middle;
      margin-left: 8px;
      border-radious:50%;
     
    }
  }

  &.right{
    text-align:right;
    align-self: center;
    & span{
      color: #367C61;
      font-family: Sailec-Bold;
      font-size: 16px;
      line-height: 23px;
      margin-right:10px;
      vertical-align:middle;
      @media (min-width: 0px) and (max-width: 767px) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}
`;

const ViewPlans = Styled.div`
h3{
    color: #195750;
    font-family: Sailec-Bold;
    font-size: 16px;
    line-height: 23px;
    margin-bottom:2px;
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 12px;
      line-height: 17px;

    }
    }
p{
    opacity: 0.5;
    color: #000000;
    font-family: Sailec-Medium;
    font-size: 12px;
    line-height: 17px;
    margin-bottom:4px;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 13px;
    }
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 10px;
      margin-bottom:0px;
      line-height: 14px;

    }
  
    }
    
    
`;

const ViewPlanDetails = Styled.div`
width: 744px;
border-radius: 10px;
background-color: #FFFFFF;
margin:0 auto;
padding: 32px 36px;   
@media (min-width: 768px) and (max-width: 1024px) {
  width:596px;
  padding:32px 28px 27px 28px;
}  
@media (min-width: 0px) and (max-width: 767px) {
   width:100%;
   padding:24px 19px 0px 19px;
}


}
span{
color: #367C61;
font-family: Sailec-Bold;
font-size: 14px;
line-height: 20px;
@media (min-width: 0px) and (max-width: 767px) {
  font-size: 12px;
  line-height: 17px;

}
}
& .main_div{
    display:flex;
    @media (min-width: 768px) and (max-width: 1024px) {
   
    }  
    
    @media (min-width: 0px) and (max-width: 767px) {
      flex-wrap: wrap;
    }
    
& .details{
    padding-right:63px;
    @media (min-width: 0px) and (max-width: 767px) {
      padding-right: 33px;
      padding-bottom:20px;
      :last-child{
        padding-bottom:0px;
      }
    }
    }
 }
`;
const Details = Styled.div`
padding:20px 0px 24px 0px;
@media (min-width: 0px) and (max-width: 767px) {
  padding:20px 0px 13px 0px;
}

`;
const Interval = Styled.div`
border-top: 1px dashed #979797;
    h3 {
        margin:20px 0px;
        @media (min-width: 0px) and (max-width: 767px) {
          margin:17px 0px 10px 0px;
        }
    }
`;

export default ViewPlan;
