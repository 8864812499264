import Styled from "styled-components";
import Input from "../../../components/atoms/Input/Input";
import DeleteIcon from "../../../assets/images/Refer/Close-1.svg";
import { isEmailValid, isPhoneNumberFormat } from "../../../utils/Utils";
import { useState } from "react";

const ReferListItem = (props) => {
  const {
    id,
    userName,
    firstName,
    lastName,
    deleteNumberHandler,
    firstNameHandler,
    lastNameHandler,
    emailPhoneHandler,
    alreadyExistsUsernameError,
    setAlreadyExistsUsernameError,
  } = props;
  const [isNumber, setIsNumber] = useState(false);

  const emailHandler = (e) => {
    setAlreadyExistsUsernameError({});
    setIsNumber(false);
    const value = e.target.value;
    if (isPhoneNumberFormat(value)) {
      setIsNumber(true);
    } else {
      if (isEmailValid(value)) {
        setIsNumber(false);
      }
    }
    emailPhoneHandler(id, value);
  };

  const fNameHandler = (e) => {
    const value = e.target.value;
    firstNameHandler(id, value);
  };

  const lNameHandler = (e) => {
    const value = e.target.value;
    lastNameHandler(id, value);
  };

  const isDuplicateUsername = alreadyExistsUsernameError?.[0]?.userName;

  console.log("isDuplicateUsername", isDuplicateUsername);

  return (
    <>
      <InviteParent>
        {/* <ContactListGrid> */}

        <div className="row custom-row">
          <div
            className="col-md-3 column1-screen"
            style={{
              paddingLeft: "5%",
            }}
          >
            <Input
              onChange={emailHandler}
              className="form-control"
              value={userName}
              type="text"
              maxLength={isNumber ? "10" : "254"}
              placeholder="Email/Phone"
            />
            {isDuplicateUsername && (
              <span
                style={{
                  fontSize: "smaller",
                  color: "red",
                }}
              >
                {isDuplicateUsername}
              </span>
            )}
          </div>

          <div className="col-md-3 column2-screen">
            {/* <div className="contactType">First Name</div> */}

            <Input
              onChange={fNameHandler}
              className="form-control"
              value={firstName}
              type="text"
              maxLength={14}
              placeholder="First Name"
            />
          </div>

          <div className="col-md-3 column3-screen">
            {/* <div className="contactType">Last Name</div> */}
            <Input
              onChange={lNameHandler}
              className="form-control"
              value={lastName}
              type="text"
              maxLength={14}
              placeholder="Last Name"
            />
          </div>

          {id !== 1 && (
            <div className="col-md-3 column4-screen">
              <div onClick={() => deleteNumberHandler(id)}>
                <img style={{ cursor: "pointer" }} src={DeleteIcon} />
              </div>
            </div>
          )}
        </div>
      </InviteParent>
    </>
  );
};

const InviteParent = Styled.div`
  border-radius: 6px;
    background-color: white;

@media (min-width: 768px) {
    padding-bottom: 0px !important;
  }
  & .custom-row{
    padding-left: 0px;
    padding-right:0px;
   & .column1-screen {
      width: 30% !important;
      flex-basis: 35% !important;
      @media (min-width: 0px) and (max-width: 767px) {
        width: 85% !important;
        flex-basis: 85% !important;
      }
    }
   & .column2-screen {
      width: 30% !important;
      flex-basis: 25% !important;
      @media (min-width: 0px) and (max-width: 767px) {
        display:none !important;
      }
    }
        
    
     & .column3-screen {
      width: 30% !important;
      flex-basis: 25% !important;
      @media (min-width: 0px) and (max-width: 767px) {
        display:none !important;
      }
    }
    & .column4-screen {
      width: 20% !important;
      display: flex;
      align-items: center;
      flex-basis: 10% !important;
      // @media (min-width: 0px) and (max-width: 767px) {
      //   width: 50% !important;
      //   flex-basis: 50% !important;
      // }
    }
  @media (min-width: 0px) and (max-width: 767px){
    border: 4px;
    border-color: rgb(244,243,240);
    border-style: solid;
    border-radius: 20px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;}
  }

& .form-check-label{
  vertical-align: middle;
  margin-left:6px;
  color: #367C61;
  font-family: Sailec-Medium;
  font-size: 14px;
  line-height: 20px;
  cursor: none;
  @media (min-width: 0px) and (max-width: 767px){
    cursor: pointer !important;
  }
}
  & .With-border{
    border: 2px solid #367C61;
  }

  & .form-check{
    margin:0px;
    cursor:pointer;
  }
    }
  & .preferedContactmobile{
        display: none;
        @media (min-width: 0px) and (max-width: 767px){
          display: block !important;
          width: 100% !important;
          line-height: 24px !important;
        }
      }
  
    & .form-check{
      text-align: center;
      & .preferedContactmobile{
        display: none;
        @media (min-width: 0px) and (max-width: 767px){
          display: block !important;
          width: 100% !important;
          line-height: 24px !important;
        }
      }
    }
  }
  & .contactType{
    display:none;
     @media (min-width: 0px) and (max-width: 767px){
        text-align: left !important;
        display: block !important;
        color: #979797 !important;
        font-family: Sailec-Medium !important;
        font-size: 12px !important;
        letter-spacing: 0 !important;
        line-height: 17px !important;
      }
  }
  }
  & .form-control{
      @media (min-width: 0px) and (max-width: 767px){
        width: 100% !important;
        
        box-sizing: border-box !important;
        // height: 50px !important;
        border: 1px solid #D8D8D8 !important;
        border-radius: 10px !important;
        background-color: #FFFFFF !important;
        color: #000000 !important;
        font-family: Sailec-Medium !important;
        font-size: 14px !important;
        letter-spacing: 0 !important;
        line-height: 20px !important;
      }
    }
  }
}
`;

export default ReferListItem;
