import authAxios from "../utils/authAxios";
import { APIURL } from "../constants/apiConstants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getPetParents = createAsyncThunk(
  "petParents/get",
  async (data, thunkAPI) => {
    try {
      const response = await authAxios.get(APIURL.PET_PARENTS);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const petParentsSlice = createSlice({
  name: "petParents",
  initialState: {
    petParents: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    updatePetParents(state, action) {
      const {
        id,
        remind,
        name,
        email,
        notes,
        phoneNumber,
        relationship,
        coOwnerId,
      } = action.payload.profile_details;
      const updatedData = state.petParents.map((p) =>
        p.coOwnerId === coOwnerId
          ? {
              ...p,
              remind: remind,
              email: email,
              name: name,
              notes: notes,
              phoneNumber: phoneNumber,
              relationship: relationship,
            }
          : p
      );
      state.petParents = updatedData;
    },

    removePetParents(state, action) {
      const updatedData = state.petParents.filter(
        (i) => i.id !== action.payload
      );
      state.petParents = updatedData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPetParents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPetParents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.petParents = action.payload?.coowner_details?.coowners;
      })
      .addCase(getPetParents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export const petParentsAction = petParentsSlice.actions;
export default petParentsSlice;
