import axios from "axios";
import { APIURL } from "../constants/apiConstants";
import { deleteCookie, getCookie, setCookie } from "./Utils";
import { differenceInDays } from "date-fns";

const authToken = getCookie("authToken");

const authAxios = axios.create({
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		Authorization: authToken,
	},
});

/*
authAxios.interceptors.request.use(
  (config) => {
	if (authToken) {
	  config.headers["Authorization"] = authToken;
	}
	return config;
  },
  (error) => {
	return Promise.reject(error);
  }
);
*/

authAxios.interceptors.response.use(
	(res) => res,
	async (err) => {
		const originalConfig = err.config;

		if (err.response) {
			if (err.response.status === 401 && !originalConfig._retry) {


				originalConfig._retry = true;

				try {
					const resp = await axios.get(APIURL.REFRESH_TOKEN, {
						headers: {
							"Content-Type": "application/json",
							Authorization: authToken ?? authAxios.defaults.headers.Authorization,
						}
					});

					const dt = new Date();

					const { token } = resp.data;
					if (token) {
						const EXPIRES_TIME = localStorage.getItem("EXPIRES_TIME");
						const expireDate = EXPIRES_TIME ? new Date(JSON.parse(EXPIRES_TIME)) : null;

						if (expireDate) {
							const numberOfDays = differenceInDays(new Date(
								expireDate.getUTCFullYear(),
								expireDate.getMonth(),
								expireDate.getDate()),
								new Date(
									dt.getFullYear(),
									dt.getMonth(),
									dt.getDate()),
							);

							if (numberOfDays) {
								setCookie("authToken", token, numberOfDays);
							} else {

								deleteCookie('authToken');
								localStorage.removeItem("EXPIRES_TIME");
								localStorage.removeItem("SESSION_EXPIRED_TIME");
								window.location.reload();
								return;
							}

						} else {
							setCookie("authToken", token);
						}

						authAxios.defaults.headers.Authorization = token;
						originalConfig.headers.Authorization = token;

						return authAxios(originalConfig);
					}

					else {
						return Promise.reject(err.response);
					}
				} catch (_error) {

					if (_error.response) {
						return Promise.reject(_error.response);
					}
					return Promise.reject(_error);
				}

			} else {
				return Promise.reject(err.response);
			}
		}
	}
);

export default authAxios;
