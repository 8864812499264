
const paths = {
    HOME: { pathName: '/' },
    WELCOME: { pathName: '/welcome' },
    LOGIN: { pathName: 'login' },
    VERIFY_OTP: { pathName: 'verify-otp' },
    SIGN_UP: { pathName: 'sign-up' },
    ADD_PET: { pathName: '/add-pet' },
    MY_PETS: { pathName: 'my-pets' },
    BELL_NOTIFICATIONS: { pathName: '/bell-notifications' },
    PET_PROFILE: { pathName: '/pet-profile' },
    BOOK_APPOINTMENTS: { pathName: '/book-appointments' },
    APPOINTMENTS: { pathName: '/appointments' },
    MY_HEART_AND_PAW: { pathName: '/my-hp' },
    PROFILE: { pathName: 'profile' },
    SETTING: { pathName: 'setting' },
    PLANS: { pathName: 'plans' },
    LOCATION: { pathName: 'location' },
    INVOICES: { pathName: 'invoices' },
    LOGOUT: { pathName: 'logout' },
    BOOK_NOW: { pathName: '/book-now' }
}

export default paths;