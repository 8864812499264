import axios from 'axios';
import { APIURL } from '../constants/apiConstants';

import authAxios from "../utils/authAxios";

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getBreeds = createAsyncThunk('breed/get', async (data, thunkAPI) => {

    try {
        const response = await authAxios.get(APIURL.BREEDS);
        return response.data;
    } catch (error) {
        thunkAPI.rejectWithValue(error);
    }
});


const breedsSlice = createSlice({

    name: 'breeds',
    initialState: {
        breeds: [],
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBreeds.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBreeds.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.breeds = action.payload;
            })
            .addCase(getBreeds.rejected, (state) => {

                state.isLoading = false;
                state.isError = true;
            })
    }
});

export default breedsSlice;






