import React from "react";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {

        console.log('error-->>', error);

        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.log('error===>>>', error, info);
    }
    render() {
        if (this.state.hasError) {
            return (<>
                Error Occurred
            </>);
        }
        return this.props.children;
    }
}

export default ErrorBoundary;