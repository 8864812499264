import React, { useState } from "react";
import Styled from "styled-components";
import { Helmet } from "react-helmet";
import {
  generateId,
  isEmailValid,
  isPhoneNumberFormat,
} from "../../../utils/Utils";
import ReferListItem from "../../../pages/myHeartAndPaw/ReferAFriend/ReferListItem";
import QRCode from "react-qr-code";
import { StyledButtonGreen } from "../Buttons/Button.style";
import { SUCCESS_ALERT_TIMEOUT } from "../../../constants";
import authAxios from "../../../utils/authAxios";
import { APIURL } from "../../../constants/apiConstants";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import Success_alert from "../../../assets/images/Success_alert.svg";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import Twitter from "../../../assets/images/Refer/twitterX.svg";
import Facebook from "../../../assets/images/Refer/facebook.svg";
import Linkedin from "../../../assets/images/Refer/linkedin.svg";
import {
  LinkedinIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import ButtonSpinner from "../../UI/Loader/ButtonSpinner";
import { getReferDetails } from "../../../store/referSlice";

const InviteFriends = (props) => {
  const [ContactList, setContactList] = useState([
    { id: 1, userName: "", firstName: "", lastName: "" },
  ]);

  const { clientId } = props;
  const [text, setText] = useState("Copy Link");
  const [successAlert, setSuccessAlert] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isMessage, setIsMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [alreadyExistsUsernameError, setAlreadyExistsUsernameError] = useState(
    {}
  );
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const referralLink = `${
    process.env.REACT_APP_BOOKING_TOOL_URL
  }/?refereeId=${encodeURIComponent(clientId)}#/refer-link`;

  const addNumberHandler = () => {
    const contact = [...ContactList];
    contact.push({
      id: generateId(6),
      userName: "",
      firstName: "",
      lastName: "",
    });

    setContactList(contact);
    // console.log("contact", ContactList);
  };
  const deleteNumberHandler = (id) => {
    if (id === 1) {
      return; // Do not delete the first field
    }
    const mappedArr = ContactList.filter((contact) => contact.id !== id);
    setContactList(mappedArr);
  };

  const emailPhoneHandler = (id, input) => {
    setAlreadyExistsUsernameError({});
    const contact = [...ContactList];
    const updatedContactData = contact.map((contact) =>
      contact.id == id ? { ...contact, userName: input } : contact
    );
    setContactList(updatedContactData);
  };
  const firstNameHandler = (id, input) => {
    const contact = [...ContactList];
    const updatedContactData = contact.map((contact) =>
      contact.id == id ? { ...contact, firstName: input } : contact
    );
    setContactList(updatedContactData);
  };
  const lastNameHandler = (id, input) => {
    const contact = [...ContactList];
    const updatedContactData = contact.map((contact) =>
      contact.id == id ? { ...contact, lastName: input } : contact
    );
    setContactList(updatedContactData);
  };

  const copy = async () => {
    await navigator.clipboard.writeText(referralLink);
    setText("Copied");
    setTimeout(() => {
      setText("Copy Link");
    }, SUCCESS_ALERT_TIMEOUT);
  };

  const submitHandler = () => {
    setIsSubmit(true);
    setAlreadyExistsUsernameError({});
    const payload = ContactList.map((contact) => {
      return {
        givenName: contact.firstName,
        familyName: contact.lastName,
        userName: contact.userName,
      };
    });

    authAxios
      .post(`${APIURL.REFER_INVITE}`, {
        invites: payload,
      })
      .then((response) => {
        setIsSubmit(false);
        if (response.status == 200) {
          setSuccessAlert(true);
          setIsMessage(response.data.message);
          setTimeout(() => {
            setSuccessAlert(false);
            setContactList([
              { id: 1, userName: "", firstName: "", lastName: "" },
            ]);
            dispatch(getReferDetails());
          }, SUCCESS_ALERT_TIMEOUT);
        }
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.status === 400)
          setAlreadyExistsUsernameError(error.data?.alreadyExists);
        else setIsError(error?.data?.message);
      });
  };

  const onCloseErrorHandler = () => {
    setIsError(null);
  };

  const isFormValid = ContactList.every((data) => {
    if (isPhoneNumberFormat(data.userName)) {
      return true;
    } else {
      if (isEmailValid(data.userName)) {
        return true;
      }
      return false;
    }
  });

  return (
    <>
      <Helmet>
        <title>Refer A Friend | My H+P</title>
        <meta
          name="description"
          content="Get 24/7 access to your pet's medical records and info, review upcoming & past appointments, and find Heart + Paw and H+P Family Partner centers near you."
        />
      </Helmet>

      {successAlert && (
        <SuccessAlert onHide={() => setSuccessAlert(false)} show={successAlert}>
          <img src={Success_alert} className="success_img" />
          <h3>{isMessage}</h3>
        </SuccessAlert>
      )}

      {isError && (
        <ErrorAlert
          show={isError ? true : false}
          defaultMessage={isError}
          onClose={onCloseErrorHandler}
        ></ErrorAlert>
      )}

      <Final>
        <Parent>
          <H3>Invite Friends</H3>
          <InviteParent>
            <ContactListGrid>
              <div className="title">
                <div
                  className="row custom-row"
                  style={{
                    width: "100%",
                    height: "36px",
                    borderRadius: "6px",
                    fontFamily: "Sailec-Medium",
                  }}
                >
                  <div
                    className="col-md-3 column1-screen"
                    style={{
                      lineHeight: "18px",
                      fontSize: "13px",
                      color: "#2B2B2B",
                      paddingTop: "20px",
                      width: "30% !important",
                      paddingLeft: "5.7%",
                    }}
                  >
                    Email/Phone*
                  </div>
                  <div
                    className="col-md-3 column5-screen"
                    style={{
                      lineHeight: "18px",
                      fontSize: "13px",
                      color: "#2B2B2B",
                      paddingTop: "20px",
                      width: "30% !important",
                      paddingLeft: "5.7%",
                    }}
                  >
                    Email Address/ Phone Number*
                  </div>
                  <div
                    className="col-md-3 column2-screen"
                    style={{
                      lineHeight: "18px",
                      fontSize: "13px",
                      color: "#2B2B2B",
                      paddingTop: "20px",
                      paddingLeft: "3.5%",
                    }}
                  >
                    First Name
                  </div>
                  <div
                    className="col-md-3 column3-screen"
                    style={{
                      lineHeight: "18px",
                      fontSize: "13px",
                      color: "#2B2B2B",
                      paddingTop: "20px",
                      paddingLeft: "4.7%",
                    }}
                  >
                    Last Name
                  </div>
                </div>
              </div>
            </ContactListGrid>

            {ContactList?.map((data, i) => (
              <ReferListItem
                alreadyExistsUsernameError={alreadyExistsUsernameError[i]}
                id={data.id}
                userName={data.userName}
                firstName={data.firstName}
                lastName={data.lastName}
                deleteNumberHandler={deleteNumberHandler}
                emailPhoneHandler={emailPhoneHandler}
                firstNameHandler={firstNameHandler}
                lastNameHandler={lastNameHandler}
                setAlreadyExistsUsernameError={setAlreadyExistsUsernameError}
              />
            ))}

            <AddFriends>
              {ContactList?.length === 10 ? (
                ""
              ) : (
                <div className="add-more">
                  <span
                    onClick={addNumberHandler}
                    style={{ cursor: "pointer" }}
                  >
                    + Add More{" "}
                  </span>
                </div>
              )}
            </AddFriends>
            <div className="submit">
              {
                <StyledButtonGreen
                  style={{
                    width: "30%",
                  }}
                  className="submit-button"
                  onClick={submitHandler}
                  disabled={isSubmit || !isFormValid}
                >
                  {isSubmit ? (
                    <ButtonSpinner>Sending Invite...</ButtonSpinner>
                  ) : (
                    "Send Invite"
                  )}
                </StyledButtonGreen>
              }
            </div>
          </InviteParent>
        </Parent>
        <SubParent>
          <H3>Share your Referral Link</H3>
          <InviteParent>
            <div className="qr-parent">
              <div
                className="qr"
                style={{
                  padding: "16px",
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: "50%",
                  width: "100%",
                }}
              >
                <QRCode
                  size={100}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={referralLink}
                  viewBox={`0 0 100 100`}
                />
              </div>

              <div className="qr-text">booking.heartandpaw.com</div>
              <div className="icons">
                <TwitterShareButton url={referralLink}>
                  <div className="button-col">
                    <div className="button-align">
                      <button type="button" className="btn btn-light">
                        <img src={Twitter} />
                      </button>
                    </div>
                  </div>
                </TwitterShareButton>
                <FacebookShareButton url={referralLink}>
                  <div className="button-col">
                    <div className="button-align">
                      <button type="button" className="btn btn-light">
                        <img src={Facebook} />
                      </button>
                    </div>
                  </div>
                </FacebookShareButton>
                <LinkedinShareButton url={referralLink}>
                  <div className="button-col">
                    <div className="button-align">
                      <button type="button" className="btn btn-light">
                        <img src={Linkedin} />
                      </button>
                    </div>
                  </div>
                </LinkedinShareButton>
              </div>
              <div className="qr-button">
                <StyledButtonGreen
                  style={{ width: "65%" }}
                  onClick={copy}
                  disabled={text == "Copied"}
                >
                  {text}
                </StyledButtonGreen>
              </div>
            </div>
          </InviteParent>
        </SubParent>
      </Final>
    </>
  );
};

const AddFriends = Styled.div`
& .add-more{
    padding-left: 20px;
    color: #367c61;
    text-decoration: underline;
    font-weight: lighter;
  }
`;
const Final = Styled.div`
  
    display: flex;
  @media (min-width: 0px) and (max-width: 767px){
       display: block !important;
  }
`;

const InviteParent = Styled.div`
    border: 2px;
    border-color: #E2E2E2;
    border-style: dashed;
    background-color: white;

  & .submit{
    text-align: center;
    padding-top: 15px; 
    padding-bottom: 20px;
    & .submit-button{
       @media (min-width: 0px) and (max-width: 767px){
       width: 90% !important;
  }
    }
  }
  & .qr-parent{
    
    & .qr-text{
      text-align: center;
      padding-bottom: 10px;  
      @media (min-width: 768px) and (max-width: 1024px){
          font-size: 12px !important;
      }
    }

    & .qr-button{
      text-align: center;
      padding-bottom: 15px;
    }
    & .icons{
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;

    @media (min-width: 0px) and (max-width: 767px){ justify-content: space-evenly;}
      & .button-col{
    
    
    // @media (min-width: 0px) and (max-width: 767px){
    //   display: flex !important;
    //   width: 100% !important;
    //   padding-left: 0px !important;
    // }
    & .button-align{
      @media (min-width: 0px) and (max-width: 767px){
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-bottom: 15px;
      }
      & .btn-light{
        box-sizing: border-box;
        height: 40px;
        width: 49px;
        border: 1px solid rgb(255, 255, 255);
        
        background-color: rgb(255, 255, 255);
      }
    
    }
  }
    }
  }
  
`;

const Parent = Styled.div`
    width: 70%;
    padding: 12px 12px 0px 12px;
    @media (min-width: 0px) and (max-width: 767px){
       width: 100% !important;
  }
`;
const SubParent = Styled.div`
    width: 30%;
    padding: 12px 12px 0px 12px;
  @media (min-width: 0px) and (max-width: 767px){
       width: 100% !important;
  }
`;
const ContactListGrid = Styled.div`
  & .title{
    // @media (min-width: 0px) and (max-width: 767px){
    //    display: none;
    //   }
  }
  & .row {
   @media (min-width: 0px) and (max-width: 767px){
       
      }
    & .col-md-3 {
      text-align: left;
      & .btn-success {
        color: #ffffff;
		font-size: 12px;
		height: 45px;
		width: 94px;
		font-family: Sailec-Medium;
      }
    }
    & .col-md-1 {
      & .btn-light {
		box-sizing: border-box;
		height: 45px;
		width: 54px;
		border: 1px solid #D8D8D8;
		border-radius: 10px;
		background-color: #FFFFFF;
      }
      
    }
  }
  & .custom-row{
  padding-right: 0px;
    padding-left: 0px;
  // padding-bottom: 20px;
  & .column1-screen {
      width: 30% !important;
      flex-basis: 35% !important;
       @media (min-width: 0px) and (max-width: 767px) {
        display:none !important;
      }
    }
    & .column5-screen {
      display: none;
         
      @media (min-width: 0px) and (max-width: 767px) {
        width: 100%% !important;
        flex-basis: 100% !important;
        display:flex !important;
      }
    }
   & .column2-screen {
      width: 30% !important;
      flex-basis: 25% !important;
      @media (min-width: 0px) and (max-width: 767px) {
        display: none !important;
      }
    }
        
    
     & .column3-screen {
      width: 30% !important;
      flex-basis: 25% !important;
      @media (min-width: 0px) and (max-width: 767px) {
        display: none !important;
      }
    }
    & .column4-screen {
      width: 20% !important;
    
    flex-basis: 10% !important;
      @media (min-width: 0px) and (max-width: 767px) {
       display: none !important;
      }
    }
}
`;

const H3 = Styled.h3`
  color: #195750 !important;
  font-family: "Sailec-Medium" !important;
  font-size: 18px !important;
  line-height: 26px !important;
  margin-bottom: 15px !important;
  display:inline-flex !important;
position:relative !important;
position: relative !important;
justify-content: left !important;
width: 100% !important;
    font-weight: bold;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px !important;  
  }
  @media (min-width: 0px) and (max-width: 768px) {
    padding-top: 20px;  
  }

`;
export default InviteFriends;
