import { createSlice } from "@reduxjs/toolkit";


const addPetSlice = createSlice({
    name: 'addPet',
    initialState: {
        petType: null,
        birthday: '',
        breed: '',
        latestWeight: '',
        name: '',
        sex: '',
        spayedOrNeutered: ''
    },
    reducers: {

        setBreed: (state, action) => {
            state.breed = action.payload;
        },

        setPetDetails: (state, action) => {

            const { name, sex, latestWeight, breed, birthday, spayedOrNeutered } = action.payload;

            state.name = name;
            state.sex = sex;
            state.latestWeight = latestWeight;
            state.breed = breed;
            state.birthday = birthday;
            state.spayedOrNeutered = spayedOrNeutered;
        },
        reset(state) {
            state.petType = null;
            state.birthday = '';
            state.breed = '';
            state.latestWeight = '';
            state.name = '';
            state.sex = '';
            state.spayedOrNeutered = '';

        },
        resetPetInfo(state) {

            state.birthday = '';
            state.breed = '';
            state.latestWeight = '';
            state.name = '';
            state.sex = '';
            state.spayedOrNeutered = '';
        },
        setPetType: (state, action) => {
            state.petType = action.payload
        }
    }
});

export const addPetAction = addPetSlice.actions;
export default addPetSlice;
