import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authAxios from "../utils/authAxios";
import { APIURL } from "../constants/apiConstants";
import { format, addWeeks, addYears } from "date-fns";
import { retrieveFromSession, saveToSession } from "../utils/Utils";

export const getMyPets = createAsyncThunk(
  "myPets/get",
  async (data, thunkAPI) => {
    try {
      const date = new Date();
      //const nextWeek = data.selectedDate ? data.selectedDate : addWeeks(date, 2);

      // console.log('date-->>', date, 'nextWeek-->>', nextWeek)
      //const start_date = format(date, 'yyyy-MM-dd');
      //const end_date = format(new Date(nextWeek), 'yyyy-MM-dd');

      //const addWeek = addWeeks(new Date(data.endMonthDate), 2);
      //const start_date = format(new Date(data.startMonthDate), 'yyyy-MM-dd');
      //const end_date = format(addWeek, 'yyyy-MM-dd');

      let start_date;
      let end_date;
      let is_calendar_view;

      if (data.activeTimelineView === 1) {
        start_date = format(date, "yyyy-MM-dd");
        end_date = format(addYears(date, 2), "yyyy-MM-dd");
        is_calendar_view = false;
      } else {
        start_date = format(new Date(data.startMonthDate), "yyyy-MM-dd");
        end_date = format(new Date(data.endMonthDate), "yyyy-MM-dd");
        is_calendar_view = true;
      }

      const response = await authAxios.get(
        `${APIURL.GET_MYPETS}?start_date=${start_date}&end_date=${end_date}&is_calendar_view=${is_calendar_view}`
      );
      saveToSession("HP_PETS", response.data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const petsData = retrieveFromSession("HP_PETS");

const myPetsSlice = createSlice({
  name: "myPets",
  initialState: {
    myPets: petsData?.client_pets ?? [],
    deceasedPets: petsData?.client_deceased_pets ?? [],
    timeLines: petsData?.time_line ?? [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    setMyPets(state, action) {
      state.myPets = action.payload;
    },
    reset(state) {
      state.myPets = [];
      state.deceasedPets = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyPets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyPets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.myPets = action.payload.client_pets;
        state.deceasedPets = action.payload.client_deceased_pets;
        state.timeLines = action.payload.time_line;
      })
      .addCase(getMyPets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.myPets = [];
        state.message = action.payload;
      });
  },
});

export const myPetsAction = myPetsSlice.actions;
export default myPetsSlice;
