import React from "react";
import styled from "styled-components";
import paths from "../../../constants/paths";
import Back from "../../../assets/images/PetProfile/Back.svg";
import { Link, useNavigate } from "react-router-dom";
import WhiteBack from "../../../assets/images/Refer/Back.svg";

const Backbtn = (props) => {
  const { path, isWhite } = props;

  const navigate = useNavigate();

  const goBackHandler = () => {
    if (path) navigate(path);
    else navigate(-1);
  };

  return (
    <Backbtns className="backbutton_container">
      <div className={`${props.class_cus || "null"} cus_backbtn`}>
        <CommonHeading>
          {/* {props.Back &&    */}

          <span onClick={goBackHandler} className="back_btn">
            <img src={isWhite ? WhiteBack : Back} />
          </span>

          {/*   
                  
                           {props.backBtnLink && <Link to={props.backBtnLink} className="back_btn">
                        <img src={Back} />
                    </Link>}

                    */}

          {props.heading && <h4>{props.heading}</h4>}

          {props.path && (
            <Link to={props.path} className="edit_btn">
              <img src={props.edit} />
            </Link>
          )}
        </CommonHeading>
      </div>
    </Backbtns>
  );
};

const Backbtns = styled.div`
  & .edit {
    h4 {
      margin-left: auto;
    }

    .edit_btn {
      margin-left: auto;
    }
  }
`;

const CommonHeading = styled.div`
  display: flex;
  position: relative;
  position: relative;
  justify-content: center;
  margin-bottom: 28px;

  & .back_btn {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0%, -50%);
    padding: 0px 20px 0px 0px;
    cursor: pointer;
  }
  & h4 {
    color: #195750;
    font-family: Sailec-Bold;
    font-size: 18px;
    line-height: 35px @media (min-width: 320px) and (max-width: 767px) {
      height: 18px;
      width: 116px;
      font-family: Sailec-Bold;
      line-height: 26px;
      text-align: center;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
      height: 24px;
      // width: 155px;
      color: #195750;
      font-family: Sailec-Bold;
      font-size: 24px;
      line-height: 35px;
    }
    @media (min-width: 767px) {
      font-size: 24px;
    }
  }
`;
export default Backbtn;
