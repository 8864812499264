import authAxios from "../../utils/authAxios";
import { APIURL } from "../../constants/apiConstants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getLocationDetails = createAsyncThunk('locationDetails/get', async (data, { rejectWithValue }) => {

    try {

        const response = await authAxios.post(APIURL.LOCATION_DETAILS, {
            location_id: data.locationId
        })
        return response.data;
    } catch (err) {

        return rejectWithValue(err.data);
    }

})

const locationDetailsSlice = createSlice({

    name: 'locationDetails',
    initialState: {
        location_details: {},
        is_preferred_contact: null,
        isLoading: false,
        isError: false,
        error: { isTrue: false },
        isSuccess: false
    },
    reducers: {
        resetError(state) {
            state.error = { isTrue: false }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocationDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLocationDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = { isTrue: false };
                state.location_details = action.payload.location_details;
                state.is_preferred_contact = action.payload.is_preferred_contact;

            })
            .addCase(getLocationDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.location_details = {};
                state.is_preferred_contact = null;
                state.error = { isTrue: true, ...action.payload };
            })
    }
});

export const locationDetailsAction = locationDetailsSlice.actions;
export default locationDetailsSlice;