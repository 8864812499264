import React from 'react';
import Styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Dropdown from '../../../assets/images/Addpet/Dropdown-1.svg'

const Input = (props) => {

    const { label, type, placeholder ,value, dropdown,...others} = props;

    return (
        <Inputs>

            <Form.Group className="cus_inputBox mb-3" controlId="formBasicEmail">
        
                <Form.Label>{label}</Form.Label>
                <div className='control_drop'>
                <Form.Control type={type} placeholder={placeholder} value={value} {...others}/>
                 
               { dropdown && <span className="dropdown">
                <img src={Dropdown} className="dropdown_img"/>
               </span>
              
               }
                </div>
            </Form.Group>
        </Inputs>
    );
};


const Inputs = Styled.div`
& .cus_inputBox{
    & .form-label{
        opacity: 0.7;
        color: #2B2B2B;
        font-family: Sailec-Medium;
        font-size: 14px;
        line-height: 20px;
        margin-bottom:12px; 
        margin-left:11px;

        @media (min-width: 0px) and (max-width: 767px) {
            font-size: 12px;

        }
      
    }
    & .form-control{
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        background-color: #FFFFFF;
        color: #2B2B2B;
        font-size: 16px;
        line-height: 22px;

        height: 50px;

        ::placeholder { 
            color: #c3c3c3;
            opacity: 1; 
            }

            :-ms-input-placeholder { 
            color: #c3c3c3;
            }

            ::-ms-input-placeholder { 
            color: #c3c3c3;
            }
            @media (min-width: 0px) and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
              
            }
    }

    
}
`

export default Input;