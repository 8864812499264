import axios from "axios";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Loader from "../components/atoms/Loader/Loader";
import { APIURL } from "../constants/apiConstants";
import { authAction } from "../store/authSlice";
import { useDispatch } from "react-redux";
import paths from "../constants/paths";
import ErrorAlert from "../components/atoms/ErrorAlert/ErrorAlert";
import { Helmet } from "react-helmet";

const RedirectToMyReferPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirectError, setIsRedirectError] = useState(null);
  const cvi = searchParams.get("cvi");

  useEffect(() => {
    if (cvi) {
      setIsRedirectError(null);
      setIsLoading(true);
      axios
        .post(APIURL.REFER_VERIFY, { cvi })
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            console.log("response", response);
            dispatch(authAction.setLoginOnSuccess(response.data));
            const redirectUrlToProfile = `/refer-friend?cvi=${cvi}&redirect=true`;
            navigate(redirectUrlToProfile);
          } else {
            navigate(paths.WELCOME.pathName);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsRedirectError(error?.response?.data?.message);
        });
    } else {
      navigate(paths.WELCOME.pathName);
    }
  }, [cvi, setIsLoading]);

  const onCloseErrorHandler = () => {
    setIsRedirectError(null);
    navigate("/");
  };

  //   if (isLoading) return <Loader />;

  //   if (isRedirectError)
  //     return (
  //       <ErrorAlert
  //         show={isRedirectError ? true : false}
  //         defaultMessage={isRedirectError}
  //         onClose={onCloseErrorHandler}
  //       ></ErrorAlert>
  //     );

  return (
    <>
      <Helmet>
        <title>Refer A Friend Redirect | My H+P</title>
        <meta
          name="description"
          content="Get 24/7 access to your pet's medical records and info, review upcoming & past appointments, and find Heart + Paw and H+P Family Partner centers near you."
        />
      </Helmet>
      {isRedirectError && (
        <ErrorAlert
          show={isRedirectError ? true : false}
          defaultMessage={isRedirectError}
          onClose={onCloseErrorHandler}
        ></ErrorAlert>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default RedirectToMyReferPage;
