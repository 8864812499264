import { Modal } from 'react-bootstrap';
import Styled from 'styled-components';

const SuccessAlert = (props) => {

    const { show, onClose, ...rest } = props;

    return (
        <>
            <SuccessAlerts
                show={show}
                onHide={onClose}
                keyboard={false}
                centered
                {...rest}
            >
                {/*
                <Modal.Header>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                */}
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </SuccessAlerts>
        </>
    );

}

const SuccessAlerts=Styled(Modal)`
  & .modal-dialog{
    width: 246px;
   
    margin:0 auto;
    & .modal-body{
        padding:49px 20px 59px 20px;
        text-align:center;
        & .success_img{
            margin-bottom:11px;
        }
        & .Success_heading{
            color: #000000;
            font-family: Sailec-Bold;
            font-size: 18px;
            line-height: 20px;
            text-align: center
        }
    }
  }
 
`

export default SuccessAlert;

