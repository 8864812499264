import axios from "axios";
import { APIURL } from "../../constants/apiConstants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { retrieveFromSession, saveToSession } from "../../utils/Utils";

export const getLocations = createAsyncThunk("locations/get", async () => {
  const response = await axios.get(APIURL.LOCATIONS);
  saveToSession("HP_LOCATIONS", response.data);
  return response.data;
});

const locationsData = retrieveFromSession("HP_LOCATIONS");

const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    locations: locationsData ?? [],
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        const filterLocation = action.payload?.filter(
          (loc) => loc.meta.location_meta_client_portal === true
        );
        state.locations = filterLocation;
      })
      .addCase(getLocations.rejected, (state) => {
        state.isError = true;
      });
  },
});

export default locationsSlice;
