import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from "../../src/utils/authAxios";
import { APIURL } from "../../src/constants/apiConstants";

export const getMyPlanDetails = createAsyncThunk('myPlanDetails/get', async (data, thunkAPI) => {

    try {
        const response = await authAxios.post(APIURL.PLAN_DETAILS, {
            plan_id: data.plan_id
        })
        return response.data;
    } catch (error) {

        return thunkAPI.rejectWithValue(error.data);
    }
});


const myPlanDetailsSlice = createSlice({

    name: 'myPlanDetails',
    initialState: {
        myPlanDetails: {},
        isLoading: false,
        isSuccess: false,
        error: { isTrue: false }
    },
    reducers: {
        resetError(state) {
            state.error = { isTrue: false };
        }
    }
    ,
    extraReducers: (builder) => {

        builder
            .addCase(getMyPlanDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMyPlanDetails.fulfilled, (state, action) => {
                state.error = { isTrue: false }
                state.isLoading = false;
                state.isSuccess = true;
                state.myPlanDetails = action.payload.plan_details;

            })
            .addCase(getMyPlanDetails.rejected, (state, action) => {
                state.myPlanDetails = {};
                state.isLoading = false;
                state.isSuccess = false;
                state.error = { isTrue: true, ...action.payload };
            })
    }

});
export const myPlanDetailsAction = myPlanDetailsSlice.actions;
export default myPlanDetailsSlice;