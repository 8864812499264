import authAxios from "../utils/authAxios";
import { format, addYears } from "date-fns";
import { APIURL } from "../constants/apiConstants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getPetProfile = createAsyncThunk(
  "petProfile/get",
  async (data, { rejectWithValue }) => {
    const date = new Date();
    const nextWeek = addYears(date, 2);
    const start_date = format(date, "yyyy-MM-dd");
    const end_date = format(nextWeek, "yyyy-MM-dd");

    try {
      const response = await authAxios.post(APIURL.GET_PET_PROFILE, {
        patient_id: data.patient_id,
        start_date: start_date,
        end_date: end_date,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data);
    }
  }
);

const petProfileSlice = createSlice({
  name: "petProfile",
  initialState: {
    petProfile: {},
    timelines: [],
    isLoading: false,
    error: { isTrue: false },
    isSuccess: false,
  },
  reducers: {
    setPetProfile(state, action) {
      state.petProfile = action.payload;
    },
    resetPetInfo(state) {
      state.petProfile = {};
    },
    resetError(state) {
      state.error = { isTrue: false };
    },
    updatePetImages(state, action) {
      const updatedPetImages = state.petProfile.petImages.filter(
        (pet) => pet._id !== action.payload
      );
      state.petProfile.petImages = updatedPetImages;
    },
    setPetProfileImage(state, action) {
      state.petProfile.profileImageUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPetProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPetProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.error = { isTrue: false };
        state.petProfile = action.payload.pet_informations;
        state.timelines = action.payload.time_line;
      })
      .addCase(getPetProfile.rejected, (state, action) => {
        state.petProfile = {};
        state.timelines = [];
        state.isLoading = false;
        state.isSuccess = false;
        state.error = { isTrue: true, ...action.payload };
      });
  },
});

export const petProfileAction = petProfileSlice.actions;
export default petProfileSlice;
