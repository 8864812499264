import { createSlice } from "@reduxjs/toolkit";

const currentLocationSlice = createSlice({

    name: 'currentLocation',
    initialState: {
        latitude: null,
        longitude: null
    },
    reducers: {
        setCurrentPosition(state, action) {

            const { coords } = action.payload;

            state.latitude = coords.latitude;
            state.longitude = coords.longitude;
        }
    }
});

export const currentLocationAction = currentLocationSlice.actions;
export default currentLocationSlice;
