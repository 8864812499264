import { Modal } from 'react-bootstrap';
import Styled from 'styled-components';
import ErrorAlertIcon from '../../../assets/images/ErrorAlert.svg';
import { APIErrorMsg } from '../../../constants/apiConstants';

const ErrorAlert = (props) => {

    const { show, onClose, defaultMessage = APIErrorMsg, defaultImage = true, ...rest } = props;

    return (
        <>
            <ErrorAlerts
                show={show}
                onHide={onClose}
                keyboard={false}
                centered
                {...rest}
            >
                <Modal.Header style={{ border: 'none' }} closeButton></Modal.Header>
                <Modal.Body>
                    {defaultImage && <img src={ErrorAlertIcon} className="success_img" alt="Error notification" />}
                    {defaultMessage ? <><h3 className='Success_heading'>{defaultMessage}</h3></> : props.children}
                </Modal.Body>
            </ErrorAlerts>
        </>
    );
}

const ErrorAlerts = Styled(Modal)`
  & .modal-dialog{
    width: 246px;
   
    margin:0 auto;
    & .modal-body{
        padding:15px 24px 59px 20px;
        text-align:center;
        & .success_img{
            margin-bottom:11px;
        }
        & .Success_heading{
            color: #000000;
            font-family: Sailec-Bold;
            font-size: 18px;
            line-height: 20px;
            text-align: center
        }
    }
  }
`

export default ErrorAlert;