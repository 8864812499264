import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authAxios from "../utils/authAxios";
import {
  getCookie,
  deleteCookie,
  setCookie,
  isEmailValid,
  isPhoneNumberValid,
  isPhoneNumberFormat,
} from "../utils/Utils";
import { APIURL } from "../constants/apiConstants";
import { storeUserSessionTime } from "../utils/Utils";
const authToken = getCookie("authToken");

const isRememberMe = null;

const user = null;

//const user = localStorage.getItem('user') && localStorage.getItem('user') != 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;

// Get user profile data
export const getMyProfile = createAsyncThunk("myProfile/get", async () => {
  const response = await authAxios.get(APIURL.MY_PROFILE);
  return response.data;
});

// For updating user notification setting
export const updateNotificationSetting = createAsyncThunk(
  "myProfile/update",
  async (data) => {
    const response = await authAxios.post(APIURL.NOTIFICATION_SETTINGS, data);
    return response.data;
  }
);

const initialState = {
  user: user ? user : {},
  userName: "", // Custom login ID
  selectedOTPOption: "",
  authToken: authToken,
  isSignedIn: !!authToken,
  isSignup: false,
  isRememberMe: isRememberMe ? isRememberMe : false,
  forwarBookingNotification: {},
  isLoading: false, // Only use for geting MyProfile (getMyProfile)
  isError: false,
  isSuccess: false, // Only use for geting MyProfile (getMyProfile)
  isUpdating: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /* set successfull login after verified user login ID */
    setLoginOnSuccess(state, action) {
      const { token, user_info } = action.payload;
      let loginType = "email";
      if (state?.userName) {
        if (isPhoneNumberFormat(state.userName)) {
          loginType = "phone";
        }
      }
      localStorage.setItem("loginType", loginType);

      if (state.isRememberMe) {
        // Set cookie for 15 days
        setCookie("authToken", token, 15);
      } else {
        localStorage.removeItem("EXPIRES_TIME");
        setCookie("authToken", token);
        storeUserSessionTime();
      }
      authAxios.defaults.headers.Authorization = token;
      // localStorage.setItem('user', JSON.stringify(user_info));

      state.authToken = token;
      state.isSignedIn = true;
      //  state.user = user_info;
      state.isSignup = false;
      state.selectedOTPOption = "";
    },

    userName(state, action) {
      const {
        username,
        isSignup,
        isRememberMe,
        selectedOTPOption = "",
      } = action.payload;
      state.userName = username;
      state.isSignup = isSignup;
      state.isRememberMe = isRememberMe;
      state.selectedOTPOption = selectedOTPOption;
    },

    setLogout(state) {
      delete authAxios.defaults.headers.Authorization;
      deleteCookie("authToken");
      localStorage.removeItem("SESSION_EXPIRED_TIME");
      localStorage.removeItem("EXPIRES_TIME");
      state.isRememberMe = false;
      state.isSignedIn = false;
      state.authToken = null;
      state.isSignup = false;
      state.userName = "";
      state.user = {};
      state.isError = false;
      state.isSuccess = false;
      state.isUpdating = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },

    // Update user contact number

    updateUserContactNumber(state, action) {
      state.user.phoneNumbers = action.payload;
    },

    // Set Forward booking varible to show the notification
    setForwarBookingNotification(state, action) {
      state.forwarBookingNotification = action.payload;
    },

    // Set Switch Appointment Status varaible [Confirmed apppt]
    setSwitchAppointmentStatus(state, action) {
      state.SwitchAppointmentStatus = action.payload;
    },

    // Update credit card
    setCreditCards(state, action) {
      const { cardconnectExpiry, cardconnectToken } = action.payload;
      state.user.cardconnectExpiry = cardconnectExpiry;
      state.user.cardconnectToken = cardconnectToken;
    },

    setDeleteCreditCards(state) {
      state.user.cardconnectExpiry = null;
      state.user.cardconnectToken = null;
    },

    reset(state) {
      state.isSignedIn = false;
      state.authToken = null;
      state.isSignup = false;
      state.userName = "";
      state.isRememberMe = false;
      state.user = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.profile_details;
      })
      .addCase(getMyProfile.rejected, (state) => {
        state.isError = true;
      })
      .addCase(updateNotificationSetting.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateNotificationSetting.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.user.externalCommPreferences = [action.payload.profile_details];
      })
      .addCase(updateNotificationSetting.rejected, (state) => {
        state.isUpdating = false;
      });
  },
});

export const authAction = authSlice.actions;
export default authSlice;
