import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import myPetsSlice from "./myPetsSlice";
import petProfileSlice from "./petProfileSlice";
import addPetSlice from "./addPetSlice";
import breedsSlice from "./breedsSlice";
import petParentsSlice from "./petParentsSlice";
import appointmentsSlice from "./appointment/appointmentsSlice";
import myProfileSlice from "./myProfileSlice";
import locationsSlice from "./location/locationsSlice";
import currentLocationSlice from "./location/currentLocationSlice";
import locationDetailsSlice from "./location/locationDetailsSlice";
import appointmentDetailsSlice from "./appointment/appointmentDetailsSlice";
import availableAppointmentsSlice from "./appointment/availableAppointmentsSlice";
import myPlanSlice from "./myPlanSlice";
import notificationDetailsSlice from "./notificationSlice";
import invoiceSlice from "./invoiceSlice";
import myPlanDetailsSlice from "./myPlanDetailsSlice";
import bellIconNotificationsSlice from "./BellIconNotifications/bellIconNotificationsSlice";
import playPackSlice from "./playPackSlice";
import myReferSlice from "./referSlice";
import themeSettingSlice from "./themeSettingSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    myPets: myPetsSlice.reducer,
    addPet: addPetSlice.reducer,
    breeds: breedsSlice.reducer,
    locations: locationsSlice.reducer,
    myProfile: myProfileSlice.reducer,
    petProfile: petProfileSlice.reducer,
    petParents: petParentsSlice.reducer,
    appointments: appointmentsSlice.reducer,
    locationDetails: locationDetailsSlice.reducer,
    currentLocation: currentLocationSlice.reducer,
    appointmentDetails: appointmentDetailsSlice.reducer,
    availableAppointments: availableAppointmentsSlice.reducer,
    myPlans: myPlanSlice.reducer,
    notification: notificationDetailsSlice.reducer,
    notificationList: bellIconNotificationsSlice.reducer,
    invoice: invoiceSlice.reducer,
    myPlanDetails: myPlanDetailsSlice.reducer,
    playPacks: playPackSlice.reducer,
    myRefer: myReferSlice.reducer,
    themeSetting: themeSettingSlice.reducer
  },
});

export default store;
