import React, { useEffect, useState } from "react";
import Backbtn from "../../../components/UI/Backbtn/Backbtn";
import Styled from "styled-components";
import { Helmet } from "react-helmet";
import ReferralCard from "../../../components/atoms/Cards/ReferralCards";
import InviteFriends from "../../../components/atoms/InviteFriends/InviteFriends";
import { StyledButtonGreen } from "../../../components/atoms/Buttons/Button.style";
import { getReferDetails } from "../../../store/referSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/atoms/Loader/Loader";
import SuccessAlert from "../../../components/atoms/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../components/atoms/ErrorAlert/ErrorAlert";
import Success_alert from "../../../assets/images/Success_alert.svg";
import authAxios from "../../../utils/authAxios";
import { APIURL } from "../../../constants/apiConstants";
import { SUCCESS_ALERT_TIMEOUT } from "../../../constants";
import RedeemIcon from "../../../assets/images/Refer/Redeem.svg";
import Email from "../../../assets/images/Refer/email.svg";
import Back from "../../../assets/images/Refer/Back.svg";
import MessageIcon from "../../../assets/images/Refer/msg.svg";
import DollarIcon from "../../../assets/images/Refer/dollarIcon.svg";
import ReferBanner from "../../../assets/images/Refer/ReferBanner.svg";
import styled from "styled-components";
import ButtonSpinner from "../../../components/UI/Loader/ButtonSpinner";

const Refer = () => {
  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isMessage, setIsMessage] = useState("");
  const [isRedeem, setIsRedeem] = useState(false);
  const [isWhite, setIsWhite] = useState(true);

  const { myRefer, isSuccess, isLoading } = useSelector(
    (state) => state.myRefer
  );

  const refer = myRefer?.referInfo;

  console.log("refer", refer);

  const redeemNowHandler = () => {
    setIsRedeem(true);
    authAxios
      .post(`${APIURL.REFER_REDEEM}`, {
        amount: refer?.currentAmount,
      })
      .then((response) => {
        setIsRedeem(false);
        console.log("message", response);
        if (response.status == 200) {
          setSuccessAlert(true);
          setIsMessage(response?.data?.addCredit?.client?.accountCredit);

          setTimeout(() => {
            setSuccessAlert(false);
            dispatch(getReferDetails());
          }, SUCCESS_ALERT_TIMEOUT);
        }
      })
      .catch((error) => {
        setIsError(true);
        setIsRedeem(false);
        setIsMessage(error?.data?.message);
      });
  };

  const onCloseErrorHandler = () => {
    setIsError(null);
  };

  useEffect(() => {
    dispatch(getReferDetails());
  }, [dispatch]);

  // if (isLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <title>Refer A Friend</title>
        <meta
          name="description"
          content="Get 24/7 access to your pet's medical records and info, review upcoming & past appointments, and find Heart + Paw and H+P Family Partner centers near you."
        />
      </Helmet>

      {isLoading && <Loader />}

      {successAlert && (
        <SuccessAlert onHide={() => setSuccessAlert(false)} show={successAlert}>
          <img src={Success_alert} className="success_img" />
          <h3>{`Amount has been credited to your account`}</h3>
        </SuccessAlert>
      )}

      {isError && (
        <ErrorAlert
          show={isError}
          defaultMessage={isMessage}
          onClose={onCloseErrorHandler}
        ></ErrorAlert>
      )}
      <HeadingElement>
        <ReferHeading>
          <div className="parent">
            <div className="backbutton">
              <Backbtn
                color="#fff"
                Back
                heading="Refer a friend and Earn $50!"
                isWhite
              />
            </div>
            <h5>
              When your friend completes their first appointment, you both get a
              $50 credit to your account.
            </h5>
          </div>
        </ReferHeading>
        <CreditBalance>
          <div className="refer-card1">
            <div className="refer-1">
              <ReferralCard
                text={"Total Credits Earned"}
                value={`$${
                  refer?.totalCreditEarn ? refer?.totalCreditEarn : "0"
                }`}
              />
            </div>

            <div className="refer-1">
              <ReferralCard
                text={"Invited Friends"}
                value={`${refer?.totalInvite ? refer?.totalInvite : "0"}`}
              />
            </div>
            <div className="refer-1">
              <ReferralCard
                text={"Accepted Friends"}
                value={`${refer?.acceptedInvite ? refer?.acceptedInvite : "0"}`}
              />
            </div>
          </div>

          <div className="refer-card2">
            <div
              className="refer-part-1"
              style={{ width: "100%", marginRight: "20px" }}
            >
              <div className="refer-1">
                <ReferralCard
                  text={"Total Credits Earned"}
                  value={`$${
                    refer?.totalCreditEarn ? refer?.totalCreditEarn : "0"
                  }`}
                />
              </div>

              <div className="refer-1">
                <ReferralCard
                  text={"Invited Friends"}
                  value={`${refer?.totalInvite ? refer?.totalInvite : "0"}`}
                />
              </div>
            </div>
            <div className="refer-part-2">
              <div className="refer-1">
                <ReferralCard
                  text={"Accepted Friends"}
                  value={`${
                    refer?.acceptedInvite ? refer?.acceptedInvite : "0"
                  }`}
                />
              </div>
            </div>
          </div>
        </CreditBalance>
      </HeadingElement>
      <PartPage>
        <span className="help-text">
          Help us grow our community of passionate pet parents and spread the
          word
        </span>
        <div className="paragraph-parent">
          <div className="paragraph">
            <div className="paragraph-image">
              <img className="email-back" src={Email} />
            </div>
            <div className="paragraph-text">
              Enter your friend’s information.
            </div>
          </div>
          <div className="paragraph">
            <div className="paragraph-image">
              <img className="email-back" src={MessageIcon} />
            </div>
            <div className="paragraph-text">
              Your friend receives an email/text link from you.
            </div>
          </div>
          <div className="paragraph">
            <div className="paragraph-image">
              <img className="email-back" src={DollarIcon} />
            </div>
            <div className="paragraph-text">
              Your friend completes the appointment and you both get a $50
              credit.
            </div>
          </div>
        </div>

        <InviteFriends clientId={refer?.encryptedVetspireId} />
      </PartPage>
    </>
  );
};

const ReferHeading = Styled.div`
& .parent{

  & h5{
    color: #fff;
  }
    & .backbutton{
        display:flex;
        position:relative;
        position: relative;
        justify-content: flex-start;
        margin-bottom:0px !important;
    & .back_btn{
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translate(0%,-50%);
        padding:0px 20px 0px 0px;
        cursor: pointer;
    }
   
    & h4{
        color: #fff;
        font-family: Sailec-Bold;
        font-size: 18px;
        line-height: 35px;
    
         margin-left:30px;
        @media (min-width: 0px) and (max-width: 767px) {
            height: 18px;
            font-size: 15px;
            font-family: Sailec-Bold;
            line-height: 26px;

        }
        @media (min-width: 767px) and (max-width: 1024px) {
            height: 24px;
            // width: 155px;
            
            font-family: Sailec-Bold;
            font-size: 24px;
            line-height: 35px;
        }
        @media (min-width: 767px){
            font-size: 24px;
        }
    }
    }
    & h5{
    margin-top: -20px !important; 
    margin-left: 30px;
    padding-bottom: 30px;

    }
}

`;
const CreditBalance = Styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 25px;
  @media (min-width: 0px) and (max-width: 767px){
      padding-left: 15px;
      display:block;
      margin-bottom: 15px;
    }
h5 {
    text-align: left;
    color: #195750;
    font-family: Sailec-Bold;
    font-size: 12px;
    line-height: 35px;
    margin-bottom: 31px;
  }
margin-bottom:35px;
& .cus_backbtn{
 margin-bottom:50px;
}

& .paragraph{
    color: #000000;
  font-family: Sailec-Medium;
  font-size: 14px;
  line-height: 24px;
  margin-bottom:24px;
}
}
& .refer-card1{
  @media (min-width: 0px) and (max-width: 767px) {
     display:none;
  }
  & .refer-1{
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    margin-right: 12px;
    @media (min-width: 0px) and (max-width: 767px) {
           
           display:none !important;
  }
  }
  @media (min-width: 768px) and (max-width: 4000px) {
    display:flex;
    justify-content: space-around;
    width:100%;
  }
}
& .refer-card2{
   @media (min-width: 0px) and (max-width: 767px) {
     display:flex;
  } 
  @ media (min-width: 768px) and (max-width: 6000px){
  display: none !important;
  }
  & .refer-part-1{
      @media (min-width: 0px) and (max-width: 767px) {
        width: 100% !important;
        margin-right: 20px !important;
      }
  }
   & .refer-part-2{
      @media (min-width: 0px) and (max-width: 767px) {
        width: 100%;
      }
  }
  & .refer-1{
    @media (min-width: 0px) and (max-width: 767px) {
          margin-bottom: 15px;
          border-radius: 10px;
          background-color: #ffffff;
          width: 100%;
          margin-right: 12px;
          width: 100%;
          border-radius: 10px;
          background-color: #ffffff;
          margin-right: 12px !important;
          
  }
  @ media (min-width: 768px) and (max-width: 6000px){
  display: none !important;
  }
  }
  @media (min-width: 0px) and (max-width: 767px) {
        width: 100%;  
        margin-right: 27px;
  }
  @media (min-width: 768px) and (max-width: 4000px) {
    display: none !important;
  }
}
`;

const PartPage = Styled.div`
  & .redeem-final{
    padding-bottom: 25px;
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
  & .redeem-parent{
    background-color: white;
    border: 2px;
    border-color: #dad6d6;
    border-style: dashed;
    & .redeem{
      display: flex;
      width: 100%;
      display: flex;
      // justify-content: space-evenly;
      @media (min-width: 0px) and (max-width: 767px) {
        padding-bottom: 15px;
      }
      & .credit{
        display: flex;
          @media (min-width: 0px) and (max-width: 767px){
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;  
          }
        & .text{
          @media (min-width: 0px) and (max-width: 767px) {
            display: none !important;
          }
        }
        & .text1{
          @media (min-width: 768px) and (max-width: 4000px) {
            display: none !important;
          }
        }  
      }
      & .sub-text{
        padding-right: 40px;
        @media (min-width: 0px) and (max-width: 767px) {
          padding-left: 12px;
          line-height: 16px;
          padding-right: 20px !important;
          padding-top: 10px;
        }
      }
      & .balance{
        color:#367c61;
        padding-left: 10px;
      }
      & .redeem-image{
        padding: 15px;
        @media (min-width: 0px) and (max-width: 767px) {
          display:none !important;
          padding: 0px !important;
        }
      }
      & .redeem-sub{
        display: flex;
        width: 100%;
         @media (min-width: 0px) and (max-width: 767px) {
          display: block !important;
          width: 90% !important;
        }
      
      & .redeem-text{
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        width: 100%;
        line-height: 25px;
        @media (min-width: 0px) and (max-width: 767px){
          display: flex !important;
          flex-direction: column-reverse !important;
        }

      }
      & .redeem-button{
        width: 35%;
        // width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 25px;
        @media (min-width: 0px) and (max-width: 767px) {
          display: inline !important;
          padding-left: 16px !important;
        }
      
      }
    }
    }  
  }
  }
  & .help-text{
    display: block;
    text-align: left;
    padding-left: 12px;
    font-size: 17px;
    padding-bottom: 15px;

     @media (min-width: 0px) and (max-width: 767px){
      font-size: 16px;
      
      padding: 12px 15px 12px 15px;
    }
  }
  & .paragraph-parent{
    display: flex;
    justify-content: space-evenly;
    padding-left: 12px;
    @media (min-width: 0px) and (max-width: 768px) {
      width: 100% !important;
          width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    }
    & .paragraph{
      background-color: #367c61;
      background-image: url(${ReferBanner});
      // width: 30%;
      
      margin-right: 15px;
      border-radius: 5px;
      padding: 10px 30px 43px 10px;
      margin-bottom: 15px;
      padding-left: 15px;
      background-color: #346F58;
      height: 260px;
      width: 100%;
    background-repeat: no-repeat;
      @media (min-width: 0px) and (max-width: 768px) {
      
      margin-right: 20px;
      width: 290px !important;
      height: 190px;
      }
      & .paragraph-image{
          padding-bottom: 15px;
        & .email{
          position: absolute;
          z-index: 2;
          padding-top: 15px;
          padding-left: 11px;
        }
        & .email-back{
          position: relative;
        }
      }
      & .paragraph-text{
        color: white;
        padding-bottom: 15px;
        font-size: 19px;
        padding-top: 15px;
        @media (min-width: 0px) and (max-width: 768px) {
           font-size: 16px;
           padding-top: 0px;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
           font-size: 15px;
           padding-top: 0px;
        }
      }
    }
  }
`;

const HeadingElement = styled.div`
  &:before {
    background-color: var(--primary-color);
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 186px;
    right: 0px;
    z-index: -1;
    @media (min-width: 0px) and (max-width: 767px) {
      height: 110px !important;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 176px !important;
    }
  }
`;

export default Refer;
