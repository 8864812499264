//export const BASE_URL = 'https://devapis.customerportal.heartandpaw.com';

import { VETSPLUSMORE_APP_NAME } from "../constants/index";

//export const BASE_URL = 'https://loginapis.heartandpaw.com';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BOOKING_API_URL = process.env.REACT_APP_BOOKING_API;
export const VETSPLUSMORE_API_URL = process.env.REACT_APP_VETSPLUSMORE_API;
export const APIErrorMsg = "Something went wrong, please try again!";
export const VETSPLUSMORE_PETCARECLUB_URL = `${process.env.REACT_APP_VETSPLUSMORE_PETCARECLUB_URL}/${VETSPLUSMORE_APP_NAME}/main/talk-to-vet`;
export const APIURL = {
  SIGNIN_CUSTOM: `${BASE_URL}/api/signin/custom`,
  SEND_OTP: `${BASE_URL}/api/signin/verify-otp`,
  GOOGLE_SIGNIN: `${BASE_URL}/api/signin/google-signin`,
  ADD_PET: `${BASE_URL}/api/v1/pet/create`,
  GET_MYPETS: `${BASE_URL}/api/v1/pet/list`,
  GET_PET_PROFILE: `${BASE_URL}/api/v1/pet/info`,
  CHECK_USERNAME: `${BASE_URL}/api/signup/check-username`,
  CREATE_USER: `${BASE_URL}/api/signup/create-user`,
  APPOINTMENT_LIST: `${BASE_URL}/api/v1/appointment/list`,
  APPOINTMENT_DETAILS: `${BASE_URL}/api/v1/appointment/details`,
  APPOINTMENT_CANCEL: `${BASE_URL}/api/v1/appointment/cancel`,
  PET_PARENTS: `${BASE_URL}/api/v1/client/co-owners`,
  BREEDS: `${BOOKING_API_URL}/api/v1/breeds`,
  MY_PROFILE: `${BASE_URL}/api/v1/client/profile`,
  UPDATE_EMAIL_PHONE: `${BASE_URL}/api/v1/client/otp-verification/email-phone`,
  UPDATE_PROFILE: `${BASE_URL}/api/v1/client/profile-update`,
  LOCATIONS: `${BASE_URL}/api/products/wp-engine/location`,
  //LOCATIONS: `https://apis.heartandpaw.com/api/products/wp-engine/location`,
  LOCATION_DETAILS: `${BASE_URL}/api/v1/location/details`,
  UPDATE_PREFERRED_LOCATION: `${BASE_URL}/api/v1/location/preferred-location`,
  AVAILABLE_APPOINTMENTS: `${BOOKING_API_URL}/api/v1/available-appointments`,
  APPOINTMENT_RESCHEDULE: `${BASE_URL}/api/v1/appointment/reschedule`,
  CONTACT: `${BASE_URL}/api/v1/location/contact`,
  CONTACT_LIST: `${BASE_URL}/api/v1/location/contact/list`,
  PLAN_LIST: `${BASE_URL}/api/v1/plan/list`,
  PLAN_DETAILS: `${BASE_URL}/api/v1/plan/details`,
  VACCINATION_PDF: `${BASE_URL}/api/v1/pet/vaccination-PDF`,
  VACCINATION_DETAIL: `${BASE_URL}/api/v1/pet/vaccination-detail`,
  VACCINATION_SHARE: `${BASE_URL}/api/v1/pet/vaccination-share`,
  LAB_DETAIL: `${BASE_URL}/api/v1/pet/lab-detail`,
  RABIES_PDF_DOWNLOAD: `${BASE_URL}/api/v1/pet/rabies-PDF`,
  RABIES_SHARE: `${BASE_URL}/api/v1/pet/rabies-share`,
  LAB_PDF: `${BASE_URL}/api/v1/pet/lab-PDF`,
  LAB_SHARE: `${BASE_URL}/api/v1/pet/lab-share`,
  NOTIFICATION_SETTINGS: `${BASE_URL}/api/v1/client/notification-update`,
  NOTIFICATION_LIST: `${BASE_URL}/api/v1/notification/list`,
  INVOICE_DETAILS: `${BASE_URL}/api/v1/client/invoice`,
  INVOICE_PDF: `${BASE_URL}/api/v1/client/invoice-download`,
  INVOICE_SHARE: `${BASE_URL}/api/v1/client/invoice-share`,
  UPDATE_CO_OWNERS_REMINDERS: `${BASE_URL}/api/v1/client/update/co-owners`,
  GOOGLE_SIGNUP: `${BASE_URL}/api/signup/google-signup`,
  CREATE_CO_OWNERS: `${BASE_URL}//api/v1/client/create/co-owners`,
  UPDATE_CO_OWNERS: `${BASE_URL}/api/v1/client/update/co-owners`,
  DELETE_CO_OWNERS: `${BASE_URL}/api/v1/client/delete/co-owners`,
  REFRESH_TOKEN: `${BASE_URL}/api/signin/refresh-token`,
  CLIENT_VERIFY_LINK: `${BASE_URL}/api/client/verify-link`,
  CREDIT_CARD: `${BASE_URL}/api/v1/client/update/card`,
  CREATE_FUTURE_APPOINTMENT: `${BASE_URL}/api/forwardbooking/link/create-appointment`,
  CANCEL_FUTURE_APPOINTMENT: `${BASE_URL}/api/forwardbooking/cancel-appointment`,
  TEXT_VERIFY_REDIRECTION: `${BASE_URL}/api/forwardbooking/text/verify-redirection`,
  APPOINTMENT_CONFIRM: `${BASE_URL}/api/appointment/confirm`,
  CONTACT_INFO: `${BASE_URL}/api/v1/client/profile/contact-info`,
  VOICE_CALL: `${BASE_URL}/api/v1/client/voice/call`,
  PET_IMAGE_UPLOAD: `${BASE_URL}/api/v1/pet/image-upload`,
  PAY_INVOICE: `${BASE_URL}/api/v1/pet/pay-invoice`,
  CREDIT_CARD_VERIFY: `${BASE_URL}/api/client/credit-card/verify`,
  SET_PROFILE: `${BASE_URL}/api/v1/pet/edit-image`,
  DELETE_IMAGE: `${BASE_URL}/api/v1/pet/remove-image`,
  ADD_IMAGE: `${BASE_URL}/api/v1/pet/add-image`,
  LOGOUT: `${BASE_URL}/api/v1/client/logout`,
  PLAY_PACKS_LIST: `${BASE_URL}/api/v1/plan/pack-list`,
  BUY_PLAY_PACKS: `${BASE_URL}/api/v1/plan/buy`,
  PET_DETAIL: `${BASE_URL}/api/v1/vetsolution/user-details/list`,
  PET_VET_SOLUTION_ADD: `${BASE_URL}/api/v1/vetsolution/register`,
  VETSPLUSMORE_REGISTER_USER: `${VETSPLUSMORE_API_URL}/client/user/${VETSPLUSMORE_APP_NAME}/v2/register-user`,
  VETSPLUSMORE_ADD_PET: `${VETSPLUSMORE_API_URL}/client/user/${VETSPLUSMORE_APP_NAME}/v2/pet/add`,
  VETSPLUSMORE_TALK_TO_VET: `${VETSPLUSMORE_API_URL}/client/user/${VETSPLUSMORE_APP_NAME}/v2/talk-to-vet`,
  REFER_INFO: `${BASE_URL}/api/v1/referfriend/info`,
  REFER_INVITE: `${BASE_URL}/api/v1/referfriend/invite`,
  REFER_REDEEM: `${BASE_URL}/api/v1/referfriend/redeem`,
  REFER_VERIFY: `${BASE_URL}/api/referfriend/verify-client`,
};
