import { type } from "@testing-library/user-event/dist/type";
import { MASK } from "../constants";

export const groupBy = (x, f) =>
  x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export const isPhoneNumberValid = (input) => {
  const phoneno = /^\d{10}$/;

  if (input?.match(phoneno)) return true;

  return false;
};

//US based phone number validation/Expression.
export const isPhoneNumberFormat = (phoneNumber) => {
  const phoneNumberFormat =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const inputTrim = phoneNumber?.trim();
  if (inputTrim?.match(phoneNumberFormat)) return true;

  return false;
};

//To be use in the backend before the validation MobileNo.
export const extractOnlyNumber = (phoneNumber) => {
  var formattedPhoneNumber = phoneNumber.match(/\d/g);
  const phoneNumberLength = formattedPhoneNumber.length;
  if (phoneNumberLength === 10) {
    return formattedPhoneNumber.join("");
  } else {
    const formattedPhoneNumbermorethanTen = formattedPhoneNumber
      .slice(1)
      .slice(-10)
      .join("");
    return formattedPhoneNumbermorethanTen;
  }
};

export const valueToMask = (value, mask = MASK) => {
  let counter = 0;
  let str = "";
  for (let i = 0; i < mask?.length; i++) {
    if (mask[i] === "_" && counter < value?.length) {
      str += value[counter];
      counter++;
    } else {
      str += mask[i];
    }
  }

  return str;
};

export const isEmailValid = (email) => {
  return /\S+@\S+\.\S+/.test(email);

  /*
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true;
  
      return false;
      */
};

export const downloadFile = (sourceData, fileName) => {
  const linkSource = `data:application/pdf;base64,${sourceData}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, "");
}

export function setCookie(name, value, days) {
  // let minutes = 10;

  var expires = "";
  if (days) {
    var date = new Date();

    // date.setTime(date.getTime() + (minutes * 60 * 1000));

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    let expiresDate = date.toUTCString();

    expires = "; expires=" + expiresDate;

    localStorage.setItem("EXPIRES_TIME", JSON.stringify(expiresDate));
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/" + ";secure";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// Get Geo location data

export function getGeoLocation(postion, type = "full_address") {
  return new Promise((resolve, reject) => {
    let geocoder;
    geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        latLng: {
          lat: postion.latitude,
          lng: postion.longitude,
        },
      },
      function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results.length > 0 && results[0]) {
            const add = results[0].formatted_address;
            const value = add.split(",");
            const count = value.length;
            const city = value[count - 3];
            const country = value[count - 1];

            if (type === "city") {
              resolve(city);
            }
            if (type === "country") {
              resolve(country);
            }

            if (type === "full_address") {
              resolve(add);
            }

            if (type === "postal_code") {
              for (let i = 0; i < results[0].address_components.length; i++) {
                let postalCode = results[0].address_components[i].types[0];

                if (postalCode === "postal_code") {
                  let postal_code =
                    results[0].address_components[i]["long_name"];

                  if (postal_code) {
                    postal_code = postal_code;

                    resolve(postal_code);
                    break;
                  }
                }
              }
            }
          } else {
            reject("address not found");
          }
        } else {
          reject(`"Geocoder failed due to, ${status}`);
        }
      }
    );
  });
}

export function getCurrentDateTime() {
  return new Date();
}

// For storing user session time on local storage (Client browser)
export function storeUserSessionTime() {
  localStorage.setItem(
    "SESSION_EXPIRED_TIME",
    JSON.stringify(getCurrentDateTime())
  );
}

// For retraving user session time data
export function retriveUserSessionTime() {
  const sessionTime = localStorage.getItem("SESSION_EXPIRED_TIME");

  if (sessionTime) return JSON.parse(sessionTime);
  else return false;
}

export function generateId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

// Get the array of years

export function getYearsRange(from = 5, to = 5) {
  const date = getCurrentDateTime();
  const year = date.getFullYear();
  const yearArr = [];
  for (let i = year - from; i <= year + to; i++) {
    yearArr.push(i);
  }
  return yearArr;
}

export function fixTimezoneOffset(date) {
  const newDate = new Date(date);
  let finalDate = new Date(
    newDate.getTime() + Math.abs(newDate.getTimezoneOffset() * 60000)
  );
  return finalDate;
}

export const saveToSession = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};

export const retrieveFromSession = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};
