import authAxios from "../utils/authAxios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIURL } from "../constants/apiConstants";

export const getReferDetails = createAsyncThunk(
  "myReferInfo/get",
  async (data, thunkAPI) => {
    try {
      const response = await authAxios.get(APIURL.REFER_INFO);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const myReferSlice = createSlice({
  name: "myRefer",
  initialState: {
    myRefer: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(getReferDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReferDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.myRefer = action.payload;
      })
      .addCase(getReferDetails.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const myReferAction = myReferSlice.actions;

export default myReferSlice;
