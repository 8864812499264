import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from "../../src/utils/authAxios";
import { APIURL } from "../../src/constants/apiConstants";

export const getNotificationDetails = createAsyncThunk('notificationDetails/get', async (data) => {

    const response = await authAxios.post(APIURL.NOTIFICATION_SETTINGS);
    return response.data;
});


const notificationDetailsSlice = createSlice({

    name: 'notificationtDetails',
    initialState: {
        isLoading: false,
        isError: false,
        emailMarketing: false,
        smsMarketing: false,
        postcardMarketing: false,
        emailTransactional: false,
        postcardTransactional: false,
        smsTransactional: false

    },
    reducers:{
        setSmsTransaction:(state,action)=>{
            state.smsTransactional=!state.smsTransactional;
        },
        setEmailTransaction:(state,action)=>{
            state.emailTransactional=!state.emailTransactional;
        },
        setPostcardTransaction:(state,action)=>{
            state.postcardTransactional=!state.postcardTransactional;
        },
        setSmsMarketing:(state,action)=>{
            state.smsMarketing=!state.smsMarketing;
        },
        setEmailMarketing:(state,action)=>{
            state.emailMarketing=!state.emailMarketing;
        },
        setPostcardMarketing:(state,action)=>{
            state.postcardMarketing=!state.postcardMarketing;
        },
    },

});

export  const notificationActions=notificationDetailsSlice.actions
export default notificationDetailsSlice;